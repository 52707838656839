import { useEffect, useState } from 'react';
import { useSearchParams, Link, useLocation } from 'react-router-dom';

import Meta from "../../components/Meta";
import Loading from "../../components/Loading";

import Data from "../../../repository";

import styles from '../../../styles/Entry.module.scss';

function VerifyEmailAddress() {
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	const [loading, setLoading] = useState(false);
	const [pin, setPin] = useState("");
	const [error, setError] = useState();
	const [resent, setResent] = useState(false)

	useEffect(() => {
		let pin = searchParams.get("pin")?.substr(0, 6);
		if(isNaN(pin)) pin = "";
		if(pin) setPin(pin);
	}, [])

	const verifyEmail = async (event) => {
		event.preventDefault();

		setLoading(true);
		try {
			await Data.verifyEmailAddress(pin);
			window.location.href = "/";
		} catch(error) {
			setError(error.response.data);
		} finally {
			setLoading(false)
		}
	}

	const requestVerifyEmail = async () => {
		await Data.resendEmailVerification();
		setResent(true);
	}

	return (
		<>
			<Meta title="Verify Email Address" />

			<div>
				{error &&
					<div className={styles.error}>
						<p>{error.message}</p>
					</div>
				}

				<h1>Verify Email Address</h1>
				<p>We've sent you an email including a unique pin code to verify your email address.</p>

				<form onSubmit={verifyEmail}>
					<input type="number" maxLength={6} disabled={loading} required={true} value={pin} onChange={(e) => { setPin(e.target.value) }} placeholder="PIN" />
					<button className="btn btnPrimary" disabled={loading}>
						{loading ?
							<Loading button={true}/>
						:
							`Verify Email`
						}
					</button>
				</form>

				<div className={styles.alternatives}>
					{resent ?
						<p>We've resent your verification email, remember to check your spam folder if you haven't received it.</p>
					:
						<p onClick={requestVerifyEmail}>Didn't receive the email?</p>
					}
					<Link to="/log-out">Logout</Link>
				</div>
			</div>
		</>
	);
}

export default VerifyEmailAddress;

import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import PayPal from "./PayPal";

import Loading from "../../components/Loading";

import Data from "../../../repository";

import styles from '../../../styles/Tokens.module.scss';

function Checkout() {
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [product, setProduct] = useState(null);
	const [code, setCode] = useState("");
	const [discountCode, setDiscountCode] = useState(null);
	const [discountLoading, setDiscountLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffect(async () => {
		try {
			const product = await Data.fetchPublicProduct(id);
			setProduct(product.data);
			setLoading(false);
		} catch(error){
			window.location.href = '/dashboard/tokens';
		}
	}, [])

	const applyCode = async () => {
		try {
			setDiscountLoading(true);
			const discountCode = await Data.checkDiscountCode(code);
			setDiscountCode(discountCode.data);
			setDiscountLoading(false);
		} catch(error){
			alert("Discount code provided is not valid.");
			setCode("");
			setDiscountLoading(false);
		}
	}

	if(loading) return <Loading button={false} />

	return (
		<section className={styles.checkout}>
			<h2>Purchase Tokens</h2>

			<div className={styles.summary}>
				<h2>{product.credits} {product.credits > 1 ? "Tokens" : "Token"}</h2>

				{discountCode ?
					<p><strike>£{(product.price / 100).toFixed(2)}</strike> £{((product.price / 100) - (product.price / 100 ) * (discountCode.percentageOff / 100)).toFixed(2)}</p>
				:
					<p>£{(product.price / 100).toFixed(2)}</p>
				}
			</div>

			{success ?
				<p className={styles.success}>Your purchase was successful!</p>
				:
				<>
					<div className={styles.discountCode}>
						{discountCode ?
							<>
								<p>Discount Applied: {discountCode.name} ({discountCode.percentageOff}% Off)</p>
								<img src={require("../../../images/cross.svg").default} alt="Remove" onClick={() => {
									setCode("");
									setDiscountCode(null);
								}} />
							</>
						:
							<>
								<input type="text" placeholder="Discount Code" value={code} onChange={(e) => { return setCode(e.target.value) }} />
								<button onClick={applyCode} className="btn btnEmpty">Apply</button>
							</>
						}
					</div>
					{!discountLoading && <PayPal product={product} setSuccess={setSuccess} discountCode={discountCode?.code} />}

					<a href="/dashboard/tokens" className="btn btnEmpty">Cancel</a>
				</>
			}
		</section>
	);
}

export default Checkout;
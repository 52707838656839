import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Loading from "../../components/Loading";

import Item from './components/Item';

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';

function List() {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    useEffect(async () => {
        await refreshUsers();
    }, [])

    const refreshUsers = async () => {
        setLoading(true);
        let users = await Data.fetchUsers();
        setUsers(users.data);
        setLoading(false);
    }

    if(loading) return <Loading button={false} />

    return (
        <div className={styles.list}>
            {!users.length && <div><p>There aren't any members to show.</p></div>}

            {users.map((user) => {
                return <Item {...user} />
            })}
        </div>
    )
}

export default List;
import {AlertCircle} from "react-feather";

const Error = ({ message }) => {
    return (
        <div className={"bg-red-100 w-full p-4 rounded-md drop-shadow-sm flex items-center gap-2"}>
            <AlertCircle size={20} className={"text-red-900"} />
            <p className={"text-red-900"}>{message}</p>
        </div>
    )
}

export default Error
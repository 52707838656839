import Header from "./components/Header";
import AccountSection from "./components/AccountSection";
import GreyLink from "./components/GreyLink";
import GoBack from "../components/GoBack";
import SplitColumns from "./components/SplitColumns";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Data from "../Data";
import moment from "moment";
import Meta from "../../components/Meta";

const Booking = () => {
    const { id } = useParams();
    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }

    const success = getQueryParam('success');

    const [booking, setBooking] = useState( null);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        try {
            const booking = await Data.fetchExperienceBooking({ id })
            console.log(booking)
            setBooking(booking.data)
        } catch(error){
            window.location.href = '/admin/bookings'
        } finally {
            setLoading(false)
        }
    }, []);

    if(!booking) return null

    return (
        <>
            <Meta title={`${booking.Event.Experience.name} (${booking.reference})`} />
            <GoBack to={"/account"} label={"Back to My Account"} />

            <Header heading={booking.reference} subHeading={'Booking Reference'} />

            {success ?
                <div className={"container mx-auto p-6 pt-0"}>
                    <div className={"bg-green-100 p-6 rounded-md border-green-300 border text-center"}>
                        <p className={"text-xl text-green-900"}>Thank you for your booking!</p>
                        <p className={"text-green-900 mt-1"}>We have sent you a confirmation email and we look forward to seeing you
                            on {moment(booking.Event.date).format("Do MMMM")}.</p>
                    </div>
                </div>
                : null}

            <div className={"container mx-auto p-6 pt-0 grid grid-cols-1 gap-4 lg:grid-cols-2"}>
                <AccountSection>
                <h3>Experience Details</h3>
                    <SplitColumns>
                        <p>Name</p>
                        <p>{booking.Event.Experience.name}</p>
                        <p>Date</p>
                        <p>{moment(booking.Event.date).format("MMM Do YYYY")}</p>
                        <p>Time</p>
                        <p>{moment(booking.Event.date).format("HH:mm A")}</p>
                        <p>Duration</p>
                        <p>{booking.Event.Experience.duration} minutes</p>
                        <p>Tickets</p>
                        <p>{booking.tickets}</p>
                        <p>Location</p>
                        <p>{booking.Event.Experience.location}</p>
                    </SplitColumns>
                </AccountSection>

                <AccountSection>
                    <h3>Booking Details</h3>
                    <SplitColumns>
                        <p>Booked On</p>
                        <p>{moment(booking.bookedOn).format("MMM Do YYYY, H:mm A")}</p>
                        <p>Booked By</p>
                        <p>{booking.User.firstName} {booking.User.lastName}</p>
                        <p>Booking Email</p>
                        <p>{booking.User.email}</p>
                    </SplitColumns>
                </AccountSection>

                <AccountSection>
                    <h3>Payment Information</h3>
                    <SplitColumns>
                        <p>Ticket Price</p>
                        <p>&pound;{booking.Event.Experience.price} each</p>
                        <p>Total Amount</p>
                        <p>&pound;{booking.totalPrice}</p>
                    </SplitColumns>
                </AccountSection>

                <AccountSection>
                    <h3>Cancellations</h3>
                    <p className={"text-textGrey"}>This booking can be cancelled for a full refund
                        until {booking.Event.Experience.refundWindow} hours prior to the experience date.</p>
                    <button
                        onClick={() => window.alert("Cancelling bookings will not work until we're hooked up to Stripe.")}
                        className={"border-0 bg-red-200 text-red-900 px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>Cancel Booking
                    </button>
                </AccountSection>
            </div>
        </>
    )
}

export default Booking
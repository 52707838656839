import {Link, Outlet} from 'react-router-dom';

import List from './List';
import CreateDiscountCodeForm from './forms/CreateDiscountCode.form';
import UpdateDiscountCodeForm from './forms/UpdateDiscountCode.form';

import Meta from "../../components/Meta";

import styles from '../../../styles/Admin.module.scss';
import Header from "../../components/Header";

function Template() {
	return (
		<>
			<Meta title="Discount Codes" />

			<Header heading={"Discount Codes"} button={<Link to="create" className="btn btnPrimary">+ Create Discount Code</Link>} />

			<main>
				<Outlet />
			</main>
		</>
	)
}

export default {
	Template,
	List,
	Create: CreateDiscountCodeForm,
	Update: UpdateDiscountCodeForm
};
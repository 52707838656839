import {Link, Outlet} from 'react-router-dom';

import List from './List';
import Existing from './Existing';

import Meta from "../../components/Meta";
import Header from "../..//components/Header";

function Template() {
	return (
		<>
			<Meta title="Minors" />

			<Header heading={"Minors"} />

			<main>
				<Outlet />
			</main>
		</>
	)
}

export default {
	Template,
	List,
	Existing
};
import {Link, Outlet} from 'react-router-dom';

import List from './List';
import Existing from './Existing';

import Meta from "../../components/Meta";

import styles from '../../../styles/Admin.module.scss';
import Header from "../../components/Header";

function Template() {
    return (
        <>
            <Meta title="Members" />

            <Header heading={"Members"} />

            <main>
                <Outlet />
            </main>
        </>
    )
}

export default {
    Template,
    List,
    Existing
};
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Loading from "../../components/Loading";

import Item from './components/Item';

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';

function List() {
	const [loading, setLoading] = useState(true);
	const [studios, setStudios] = useState([]);

	useEffect(async () => {
		await refreshStudios();
	}, [])

	const refreshStudios = async () => {
		setLoading(true);
		let studios = await Data.fetchStudios();
		studios = studios.data.sort((a, b) => {
			return b.enabled - a.enabled;
		})
		setStudios(studios);
		setLoading(false);
	}

	if(loading) return <Loading button={false} />

	return (
		<div className={styles.list}>
			{!studios.length && <div><p>There aren't any studios to show.</p></div>}

			{studios.map((studio) => {
				return <Item {...studio} />
			})}
		</div>
	)
}

export default List;
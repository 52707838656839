import { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import Loading from "../../components/Loading";
import Meta from "../../components/Meta";

import Data from "../../../repository";

import styles from "../../../styles/Entry.module.scss";

function ResetPassword() {
	const params = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		email: "",
		password: "",
		error: null
	});

	const onChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.value
		})
	}

	const onSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);

		try {
			await Data.resetPassword(state.email, state.password, params.token);
			navigate("../../");
		} catch(error) {
			setState({
				email: "",
				password: "",
				error: error.response.data
			})
		} finally {
			setLoading(false);
		}
	}

	return (
		<>
			<Meta title="Reset Password" />

			<div>
				{state.error &&
				<div className={styles.error}>
					<p>{state.error.message}</p>
				</div>
				}

				<h1>Reset Password</h1>
				<p>Enter your email address and choose a new password below.</p>

				<form onSubmit={onSubmit}>
					<input type="email" name="email" placeholder="Email Address" value={state.email} onChange={onChange} maxLength={255} required={true} />
					<input type="password" name="password" placeholder="New Password" value={state.password} onChange={onChange} maxLength={255} required={true} />
					<button className="btn btnPrimary" disabled={loading}>
						{loading ?
							<Loading button={true} />
							:
							`Reset Password`
						}
					</button>
				</form>

				<div className={styles.alternatives}>
					<Link to="/entry/login">Remember your password?</Link>
				</div>
			</div>
		</>
	);
}

export default ResetPassword;

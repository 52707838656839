import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import Loading from "../../../components/Loading";
import UpdateProductAction from "../actions/UpdateProduct.action";

import Data from '../../../../repository';

import styles from '../../../../styles/Admin.module.scss';

function UpdateProductForm(){
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [product, setProduct] = useState(null);

	useEffect(async () => {
		await fetchProduct();
	}, [])

	const fetchProduct = async () => {
		setLoading(true);
		const products = await Data.fetchProduct(id);
		setProduct(products.data);
		setLoading(false);
	}

	const updateField = (e) => {
		setProduct({
			...product,
			[e.target.name]: e.target.value
		});
	}

	if(loading) return <Loading button={false} />

	return (
		<div className={styles.form}>
			<div>
				<p>Tokens:</p>
				<input type="number" name="credits" value={product.credits} onChange={updateField} disabled={true} />
			</div>

			<div>
				<p>Price:</p>
				<input type="number" name="price" value={product.price} onChange={updateField} disabled={true} />
			</div>

			<select name="enabled" value={product.enabled} onChange={updateField}>
				<option value="true">Enabled</option>
				<option value="false">Disabled</option>
			</select>

			<UpdateProductAction {...product} />
		</div>
	)
}

export default UpdateProductForm;
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from "../../../context";

import Meta from "../../components/Meta";
import Loading from "../../components/Loading";
import Header from "../../components/Header";

import Data from '../../../repository';

import styles from '../../../styles/Account.module.scss';

function AddMinor() {
	const context = useContext(AppContext);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [name, setName] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState("");
	const [relationship, setRelationship] = useState("");
	const [alternateContact, setAlternateContact] = useState("");
	const [checkbox, setCheckbox] = useState(false);

	const submit = async (e) => {
		e.preventDefault();
		setLoading(true);
		setError("");

		try {
			await Data.createMinor({
				name,
				dateOfBirth,
				relationship,
				alternateContact
			})
			navigate(`/dashboard/settings`);
		} catch(error) {
			setLoading(false);
			setError(error.response.data.message);
		}
	}

	return (
		<>
			<Meta title="Account Details" />

			<Header heading={"Add Minor (Aged 11 - 16)"} />
			<main>
				<section>
					<form className={styles.form} onSubmit={submit}>
						<div>
							<p>Your Name:</p>
							<input type="text" value={`${context.user.firstName} ${context.user.lastName}`} disabled={true} />
						</div>

						<div>
							<p>Your Email Address:</p>
							<input type="text" value={context.user.email} disabled={true} />
						</div>

						<div>
							<p>Your Phone Number:</p>
							<input type="text" value={context.user.phoneNumber} disabled={true} />
						</div>

						<div>
							<p>Minor's Full Name:</p>
							<input type="text" value={name} onChange={(e) => { setName(e.target.value) }} required={true} maxLength={500} />
						</div>

						<div>
							<p>Minor's Date of Birth:</p>
							<input type="date" value={dateOfBirth} onChange={(e) => { setDateOfBirth(e.target.value) }} required={true} />
						</div>

						<div>
							<p>Relationship to the Minor:</p>
							<input type="text" value={relationship} onChange={(e) => { setRelationship(e.target.value) }} required={true} maxLength={500} />
						</div>

						<div>
							<p>Alternate Contact:</p>
							<input type="text" value={alternateContact} onChange={(e) => { setAlternateContact(e.target.value) }} required={true} maxLength={500} />
						</div>

						<div className={styles.document}>
							<h2>The Fit Hub Gym Policy</h2>
							<ul>
								<li>Children aged 10 or younger are not permitted to use the Fit Hub Gym.</li>
								<li>Children aged 11 – 16 may use the Fit Hub Gym with direct adult supervision.</li>
								<li>Any parent, legal guardian or approved designated adult aged 18 or over who is
									supervising the use of the Fit Hub Gym by a child aged 11 – 16 must have agreed to
									the Fit Hub Gym’s relevant terms and conditions, including this waiver and the
									separate Agreement, Release and Waiver of Liability Disclaimer.</li>
							</ul>

							<h2>Notice of Assumption of Risk – Waiver and Release</h2>
							<p>I, the undersigned parent/guardian/approved designated adult of the minor participant
								named above give my permission for said individual to exercise in the Fit Hub Gym having
								met the Fit Hub Gym’s Policy requirements listed above.</p>
							<p>I understand that engaging in any physical exercise activity or using the Fit Hub Gym for any
								purpose may pose a serious risk to health or cause death. I understand that it is strongly recommended that a physician / health professional be consulted prior to said minor
								participant using the Fit Hub Gym. I understand that after starting to use the Fit Hub Gym, if
								said minor participant notices any changes in physical condition that may indicate a health
								risk by continued use of the Fit Hub Gym, it is strongly recommended that a physician /
								health professional be consulted to ensure that it is appropriate to continue to use the Fit
								Hub Gym.</p>
							<p>I agree that if said minor participant uses the Fit Hub Gym, either I or another approved
								designated adult must accompany them at all times, and that they use the Fit Hub Gym at
								their/my own risk. I confirm I will take full responsibility for the said minor participant during
								their use of the Fit Hub Gym. I understand that I am wholly responsible for the minor
								participant’s behaviour in the Fit Hub Gym. I acknowledge, accept, and understand that
								minors aged 10 and under are not covered by the Fit Hub Gym insurance and that minors
								aged 11 – 16 are not covered under the Fit Hub Gym insurance unless accompanied and
								supervised by a parent, legal guardian or approved designated adult aged 18 or over.</p>
							<p>I, on behalf of myself, my heirs, and executors, hereby release and discharge from all liability
								and covenant not to pursue Functional Fitness Limited, its agents, officers, volunteers and
								employees, from and for any and all liability for all loss or damages, and waive any and all
								claims, demands and actions therefore, on account of injury to said minor participant’s
								person or property, including death, arising from the minor participant’s use of the Fit Hub
								Gym; and I agree to indemnify and hold Functional Fitness Limited and its agents harmless
								from any loss, liability, damage, or cost, including reasonable legal fees that may occur as a
								result of or due to said minor participant’s use of the Fit Hub Gym.</p>
							<p>I have read, understand and agree to the notice of assumption of risk - waiver and release for
								my minor participant. I warrant that I possess all the rights, powers, and privileges of a parent
								or legal guardian necessary to execute this document with binding legal effect.</p>
						</div>

						<label className={styles.checkbox}>
							<input type="checkbox" value={checkbox} onChange={(e) => { setCheckbox(!checkbox) }} />
							I have read and accept the above Waiver and Release
						</label>

						{error && <p className={styles.error}>{error}</p>}

						<div>
							<button className="btn btnPrimary" disabled={loading || !checkbox}>
								{loading ?
									<Loading button={true} />
									:
									`Submit Form`
								}
							</button>
						</div>
					</form>
				</section>
			</main>
		</>
	);
}

export default AddMinor;

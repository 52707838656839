import { useState } from 'react';
import {Link, useSearchParams, useNavigate} from 'react-router-dom';

import Loading from '../../components/Loading';
import Meta from "../../components/Meta";

import Data from '../../../repository';

import styles from '../../../styles/Entry.module.scss';

function Login() {
	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		email: "",
		password: "",
		error: null
	});

	const onChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.value
		})
	}

	const onSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);

		try {
			const result = await Data.login(state.email, state.password);

			if(result.data.token === "password-reset"){
				alert(`We've sent you a link to set a new password because we recently re-launched our booking system. Please check your emails and follow the enclosed link.`);
			} else {
				localStorage.setItem("token", result.data.token);
				window.location.href = searchParams.get("referer") ? searchParams.get("referer") : '/';
			}
		} catch(error) {
			setState({
				...state,
				password: "",
				error: error.response.data
			});
		} finally {
			setLoading(false);
		}
	}

	return (
		<>
			<Meta title="Login" />

			<div>
				{state.error &&
					<div className={styles.error}>
						<p>{state.error.message}</p>
					</div>
				}

				<h1>{searchParams.get("referer")?.includes('/join/') ? 'Login to your account below to accept your booking invitation.' : 'Login'}</h1>

				<form onSubmit={onSubmit}>
					<input type="email" name="email" placeholder="Email Address" value={state.email} onChange={onChange} maxLength={255} required={true} />
					<input type="password" name="password" placeholder="Password" value={state.password} onChange={onChange} maxLength={255} required={true} />
					<button className="btn btnPrimary" disabled={loading}>
						{loading ?
							<Loading button={true} />
						:
							`Login`
						}
					</button>
				</form>

				<div className={styles.alternatives}>
					<Link to="/entry/forgotten-password">Forgotten Password?</Link>
					<Link to="/entry/create-account">Don't have an account yet?</Link>
				</div>
			</div>
		</>
	);
}

export default Login;

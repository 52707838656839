import { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import Loading from "../../../components/Loading";

import moment from 'moment';

import Data from '../../../../repository';

import styles from '../../../../styles/Admin.module.scss';

function Existing() {
	const navigate = useNavigate();
	const { id, withheldId } = useParams();

	const [loading, setLoading] = useState(true);
	const [studio, setStudio] = useState(null);
	const [withheld, setWithheld] = useState({})
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(async () => {
		await fetchStudio();
	}, [])

	const fetchStudio = async () => {
		setLoading(true);
		const studio = await Data.fetchStudio(id);
		setStudio(studio.data);
		setWithheld(studio.data.withheld.filter((obj) => {
			return obj._id === withheldId
		})[0])
		setLoading(false);
	}

	const addPeriod = (e) => {
		e.preventDefault();
		
		setWithheld({
			...withheld,
			sessions: [
				...withheld.sessions,
				{
					day: 'Mon',
					start: '00:00',
					end: '01:00'
				}
			]
		})
	}

	const updatePeriodDay = (idx, e) => {
		let sessions = withheld.sessions;
		sessions[idx] = {
			...sessions[idx],
			day: e.target.value
		}

		setWithheld({
			...withheld,
			sessions
		})
	}

	const updatePeriodStart = (idx, e) => {
		let sessions = withheld.sessions;
		sessions[idx] = {
			...sessions[idx],
			start: e.target.value
		}

		setWithheld({
			...withheld,
			sessions
		})
	}

	const updatePeriodEnd = (idx, e) => {
		let sessions = withheld.sessions;
		sessions[idx] = {
			...sessions[idx],
			end: e.target.value
		}

		setWithheld({
			...withheld,
			sessions
		})
	}

	const removePeriod = (idx) => {
		let sessions = withheld.sessions;

		 sessions.splice(idx, 1);

		setWithheld({
			...withheld,
			sessions
		})
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		let withhelds = studio.withheld.map((obj) => {
			if(obj._id === withheldId) return withheld;
			return obj;
		});

		const newStudio = {
			...studio,
			withheld: withhelds
		}

		await Data.updateStudio(newStudio);
		navigate(`/admin/studios/${newStudio._id}`)
	}

	const handleDelete = async (e) => {
		e.preventDefault();

		let withhelds = studio.withheld.filter((obj) => {
			return obj._id !== withheldId
		});

		const newStudio = {
			...studio,
			withheld: withhelds
		}

		await Data.updateStudio(newStudio);
		navigate(`/admin/studios/${newStudio._id}`)
	}

	if(loading) return <Loading button={false} />

	return (
		<form className={styles.form}>
			<div>
				<p>Name:</p>
				<input type="text" value={withheld.name} required={true} minLength={1} onChange={(e) => { return setWithheld({ ...withheld, name: e.target.value }) }} />
			</div>
			<div>
				<p>Effective From:</p>
				<input type="date" value={withheld.start ? moment(withheld.start).format('YYYY-MM-DD') : null} onChange={(e) => { return setWithheld({ ...withheld, start: e.target.value }) }} />
			</div>
			<div>
				<p>Effective To:</p>
				<input type="date" value={withheld.end ? moment(withheld.end).format('YYYY-MM-DD') : null} onChange={(e) => { return setWithheld({ ...withheld, end: e.target.value }) }} />
			</div>
			
			<h2>Periods</h2>
			{withheld.sessions.map((period, idx) => {
				return (
					<div className={styles.period}>
						<select value={period.day} onChange={updatePeriodDay.bind(this, idx)}>
							<option value="Mon">Mon</option>
							<option value="Tue">Tue</option>
							<option value="Wed">Wed</option>
							<option value="Thu">Thu</option>
							<option value="Fri">Fri</option>
							<option value="Sat">Sat</option>
							<option value="Sun">Sun</option>
						</select>
						<input type="time" value={period.start} onChange={updatePeriodStart.bind(this, idx)} />
						<input type="time" value={period.end} onChange={updatePeriodEnd.bind(this, idx)} />
						<img src={require('../../../../images/cross.svg').default} onClick={removePeriod.bind(this, idx)} />
					</div>
				)
			})}
			<button className="btn btnEmpty" onClick={addPeriod}>+ Add Period</button>
			<button className="btn btnPrimary" disabled={buttonLoading} onClick={handleSubmit}>
				{buttonLoading ?
					<Loading button={true} />
					:
					`Save`
				}
			</button>
			<button className="btn btnDanger" disabled={buttonLoading} onClick={handleDelete}>
				{buttonLoading ?
					<Loading button={true} />
					:
					`Delete`
				}
			</button>
		</form>
	)
}

export default Existing;
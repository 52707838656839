import {Link} from "react-router-dom";
import {useContext} from "react";
import AppContext from "../../../../context";
import NavItem from "./NavItem";
import {Calendar, Grid, LogOut, Settings, ShoppingCart} from "react-feather";

const Sidebar = () => {
    const context = useContext(AppContext);

    return (
        <div className={"fixed bg-white h-dvh w-72 shrink-0 border-r p-4 flex flex-col justify-between"}>
            <div className={"flex flex-col gap-8 items-start justify-start"}>
                <Link to={'/'} className={"flex items-center gap-2"}>
                    <div className={'block w-10 h-10 rounded-full bg-primary'}>
                        <img src={context.domain.logo} alt={context.domain.name} className={"w-full"}/>
                    </div>
                    <p>{context.domain.name}</p>
                </Link>

                <nav className={"flex flex-col gap-2 w-full"}>
                    <NavItem to={"/admin/upcoming-events"} label={"Upcoming Events"} icon={<Calendar size={20} />} />
                    <NavItem to={"/admin/bookings"} label={"Bookings"} icon={<ShoppingCart size={20} />} />
                    <NavItem to={"/admin/experiences"} label={"Experiences"} icon={<Grid size={20} />} />
                    <NavItem to={"/admin/site-settings"} label={"Site Settings"} icon={<Settings size={20} />} />
                </nav>
            </div>

            <NavItem to={"/log-out"} label={"Log Out"} icon={<LogOut size={20} />} />
        </div>
    )
}

export default Sidebar
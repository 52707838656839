import {useContext, useState} from "react";
import AppContext from "../../context";

import Data  from '../../repository/';

import Loading from "./Loading";

import styles from '../../styles/Account.module.scss';

function IssueReport() {
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [category, setCategory] = useState("");
    const [report, setReport] = useState("");

    const submit = async e => {
        e.preventDefault()
        setLoading(true)
        setError(false)

        try {
            await Data.sendIssueReport({ category, report })
            setLoading(false)
            setSuccess(true)
        } catch(error){
            console.log(error)
            setError(true)
            setLoading(false)
        }

    }

    return (
        <section>
            <form className={styles.form} onSubmit={submit}>
                <h2>Report an Issue</h2>

                {success ?
                    <p>Your issue was successfully sent, we'll be in touch soon.</p>
                :
                    <>
                        {error && <p>There was an error sending your report, please try again.</p>}

                        <div>
                            <p>Your Name:</p>
                            <input type={"text"} disabled={true} value={`${context.user.firstName} ${context.user.lastName}`} />
                        </div>
                        <div>
                            <p>Email Address:</p>
                            <input type={"text"} disabled={true} value={context.user.email} />
                        </div>
                        <div>
                            <p>Category:</p>
                            <select value={category} onChange={e => setCategory(e.target.value)} required={true}>
                                <option value={""} disabled={true} selected={true}>Select a category</option>
                                {context.domain.membershipMode ?
                                    <>
                                        <option value={"Membership Plan"}>Membership Plan</option>
                                        <option value={"Billing"}>Billing</option>
                                    </>
                                :
                                    <option value={"Booking System"}>Booking System</option>
                                }
                                <option value={"Door Access"}>Door Access</option>
                                <option value={"Gym Equipment"}>Gym Equipment</option>
                                <option value={"Safety"}>Safety</option>
                                <option value={"Other"}>Other</option>
                            </select>
                        </div>
                        <div>
                            <p>Issue:</p>
                            <textarea value={report} onChange={e => setReport(e.target.value)} required={true} rows={5} />
                        </div>
                        <div>
                            <button className={"btn btnPrimary"} disabled={loading}>{
                                loading ? <Loading button={true} /> : "Send Issue Report"
                            }</button>
                        </div>
                    </>
                }
            </form>
        </section>
    )
}

export default IssueReport;
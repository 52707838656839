import { useState, useEffect } from "react";

import CreateMembershipPlanAction from "../actions/CreateMembershipPlan.action";

import styles from '../../../../styles/Admin.module.scss';

function CreateMembershipPlanForm(){
	const [membershipPlan, setMembershipPlan] = useState({
		name: "",
		description: "",
		active: true,
		interval_count: 1,
		interval: "month",
		lockstateLockId: "",
		amount: 0,
		startTime: '00:00',
		endTime: '23:59'
	});

	const updateField = (e) => {
		setMembershipPlan({
			...membershipPlan,
			[e.target.name]: e.target.value
		});
	}

	return (
		<div className={styles.form}>
			<div>
				<p>Name:</p>
				<input type="text" name="name" value={membershipPlan.name} onChange={updateField}/>
			</div>

			<div>
				<p>Description:</p>
				<input type="text" name="description" value={membershipPlan.description} onChange={updateField}/>
			</div>

			<div>
				<p>Amount:</p>
				<input type="number" name="amount" value={membershipPlan.amount} onChange={updateField}/>
			</div>

			<div>
				<p>Interval:</p>
				<div>
					<input type={'number'} name={'interval_count'} value={membershipPlan.interval_count}
						   onChange={updateField} min={0} max={365}/>
					<select name="interval" value={membershipPlan.interval} onChange={updateField}>
						<option value="day">Days</option>
						<option value="week">Weeks</option>
						<option value="month">Months</option>
						<option value="year">Years</option>
					</select>
				</div>
			</div>

			<div>
				<p>Lock ID:</p>
				<input type="text" name="lockstateLockId" value={membershipPlan.lockstateLockId}
					   onChange={updateField}/>
			</div>

			<div>
				<p>Secondary Lock ID:</p>
				<input type="text" name="secondaryLockstateLockId" value={membershipPlan.secondaryLockstateLockId}
					   onChange={updateField}/>
			</div>

			<div>
				<p>Schedule:</p>
				<div>
					<input type="time" name="startTime" value={membershipPlan.startTime}
						   onChange={updateField}/>
					<input type="time" name="endTime" value={membershipPlan.endTime}
						   onChange={updateField}/>
				</div>
			</div>

			<select name="active" value={membershipPlan.enabled} onChange={updateField}>
				<option value="true">Enabled</option>
				<option value="false">Disabled</option>
			</select>

			<CreateMembershipPlanAction {...membershipPlan} />
		</div>
	)
}

export default CreateMembershipPlanForm;
import { Link } from 'react-router-dom';

import styles from '../../../../styles/Admin.module.scss';

function Item({ _id, name, enabled }) {
	return (
		<Link to={_id} className={`${styles.studio} ${enabled ? "" : styles.disabled}`}>
			<p>{name}</p>
		</Link>
	)
}

export default Item;
import {Link} from "react-router-dom";
import {useContext} from "react";
import AppContext from "../../../context";

const Header = () => {
    const context = useContext(AppContext);

    return <div className={"border-b border-borderGrey p-4 flex justify-between items-center"}>
        <Link to={'/'} className={"flex items-center gap-2"}>
            <div className={'block w-10 h-10 rounded-full bg-primary'}>
                <img src={context.domain.logo} alt={context.domain.name} className={"w-full"}/>
            </div>
            <p>{context.domain.name}</p>
        </Link>
        <div className={"flex items-center gap-4"}>
            <Link to={"/contact"}>Contact</Link>
            {!context.user ?
                <Link to={"/login"} className={"border border-borderGrey px-3 py-1 rounded"}>Log In</Link>
                : context.user.admin ?
                    <Link to={"/admin"} className={"border border-borderGrey px-3 py-1 rounded"}>Admin Dashboard</Link>
                    :
                    <Link to={"/account"} className={"border border-borderGrey px-3 py-1 rounded"}>My Account</Link>
            }
        </div>
    </div>
}

export default Header
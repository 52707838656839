import {Link, Outlet} from 'react-router-dom';

import List from './List';
import CreateProductForm from './forms/CreateProduct.form';
import UpdateProductForm from './forms/UpdateProduct.form';

import Meta from "../../components/Meta";

import styles from '../../../styles/Admin.module.scss';
import Header from "../../components/Header";

function Template() {
	return (
		<>
			<Meta title="Pricing" />

			<Header heading={"Pricing"} button={<Link to="create" className="btn btnPrimary">+ Create Product</Link>} />

			<main>
				<Outlet />
			</main>
		</>
	)
}

export default {
	Template,
	List,
	Create: CreateProductForm,
	Update: UpdateProductForm
};
import Header from "../components/Header";
import {NavLink} from "react-router-dom";
import Empty from "../components/Empty";
import {useEffect, useState} from "react";
import Data from "../../Data";
import Error from "../components/Error";
import moment from "moment";
import Meta from "../../../components/Meta";

const Bookings = () => {

    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(async () => {
        try {
            const bookings = await Data.fetchAllExperienceBookings()
            setBookings(bookings.data)
        } catch(error){
            setError(true)
        } finally {
            setLoading(false)
        }
    }, []);

    return (
        <>
            <Meta title={"Bookings"} />
            <Header heading={"Bookings"} />
            <div className={"p-6 grid grid-cols-1 gap-6"}>

                {error ? <Error message={"Unable to load experiences, please refresh the page."} /> : null}

                {loading ?
                    <Empty>
                        <p>Loading...</p>
                    </Empty>
                    : bookings.length === 0 ?
                        <Empty>
                            <p>No Bookings</p>
                        </Empty>
                        :

                    <table className={"w-full border-collapse outline outline-1 outline-gray-200 rounded-lg overflow-hidden drop-shadow-sm"}>
                        <thead className={"bg-gray-50"}>
                        <tr>
                            <th className={"text-left px-6 py-4"}>Reference</th>
                            <th className={"text-left px-6 py-4"}>Name</th>
                            <th className={"text-left px-6 py-4"}>Experience</th>
                            <th className={"text-left px-6 py-4"}>Date</th>
                            <th className={"text-left px-6 py-4"}>Tickets</th>
                            <th className={"text-left px-6 py-4"}>Booked On</th>
                            <th className={"text-left px-6 py-4"}>Actions</th>
                        </tr>
                        </thead>
                        <tbody className={"bg-white"}>
                        {bookings.map((booking, index) => (
                                <tr className={"border-t"}>
                                    <td className={"px-6 py-4"}>{booking.reference}</td>
                                    <td className={"px-6 py-4"}>{booking.User.firstName} {booking.User.lastName}</td>
                                    <td className={"px-6 py-4"}>{booking.Event.Experience.name}</td>
                                    <td className={"px-6 py-4"}>{moment(booking.Event.date).format("MMM Do YYYY, H:mm A")}</td>
                                    <td className={"px-6 py-4"}>{booking.tickets} people</td>
                                    <td className={"px-6 py-4"}>{moment(booking.bookedOn).format("MMM Do YYYY, H:mm A")}</td>
                                    <td className={"px-6 py-2"}>
                                        <div className={"flex gap-2"}>
                                            <NavLink to={`/admin/bookings/${booking._id}`}
                                                     className={"bg-backgroundGrey px-2.5 py-1 rounded hover:opacity-80 transition"}>View</NavLink>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            </table>
                        }
                        </div>
                    </>
                    )
                }

                export default Bookings
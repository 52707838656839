import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Data from "../Data";

const CheckoutHeader = () => {
    const { id } = useParams();
    if(!id) window.location.href = "/"

    const [experience, setExperience] = useState([]);

    useEffect(async () => {
        try {
            const experience = await Data.fetchExperienceById( { id})
            setExperience(experience.data)
        } catch (error) {
            window.location.href = "/"
        }
    }, []);

    return (
        <div className={"p-8 border-b border-b-borderGrey bg-primary"}>
            <div className={"container mx-auto [&_*]:text-white"}>
                <p className={"text-textGrey opacity-70"}>Book Now</p>
                <h1 className={"text-2xl text-black"}>{experience.name}</h1>
                <div className={"[&_*]:text-textGrey flex gap-2 mt-1 opacity-70"}>
                    <p>{experience.duration} minutes</p>
                    <p>•</p>
                    <p>&pound;{experience.price} per person</p>
                </div>
            </div>
        </div>
    )
}

export default CheckoutHeader
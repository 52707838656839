import { Link } from 'react-router-dom';

import styles from '../../../../styles/Admin.module.scss';

function Item({ _id, firstName, lastName, email, balance }) {
	return (
		<Link to={_id} className={styles.user}>
			<p>{`${firstName} ${lastName} (${email})`}</p>
			<p>{balance}</p>
		</Link>
	)
}

export default Item;
import {Link} from "react-router-dom";
import {ArrowLeft} from "react-feather";

const GoBack = ({ to, label }) => {
    return (
        <div className={"px-4 py-2 border-b border-borderGrey"}>
            <Link to={to} className={"flex items-center gap-1 [&_*]:text-textGrey"}>
                <ArrowLeft size={18}/>
                <p>{label}</p>
            </Link>
        </div>

    )
}

export default GoBack
import {Link} from "react-router-dom";

const Preview = ({ experience: { _id, isPublished, name, image, shortDescription, duration, price } }) => {
    return <Link to={`/${_id}`} className={"flex flex-col"}>
        <div className={"bg-borderGrey w-full h-40 sm:h-auto sm:aspect-video rounded-t-lg shrink-0 object-cover overflow-hidden relative"}>
            {isPublished === false ? <p className={"absolute top-2 left-2 bg-red-500 text-white px-2 py-1 rounded text-sm"}>Unpublished</p> : null}
            {image ? <img src={image} alt={name} className={"w-full h-full object-cover"}/> : null}
        </div>
        <div className={"p-4 border border-t-0 border-borderGrey rounded-b-lg"}>
            <h3>{name}</h3>
            <p className={"text-textGrey"}>{shortDescription}</p>
            <div className={"[&_*]:text-textGrey flex gap-2"}>
                <p>{duration} minutes</p>
                <p>•</p>
                <p>&pound;{price} per person</p>
            </div>
        </div>
    </Link>
}

export default Preview
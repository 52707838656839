import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import AppContext from '../../context';
import { Capacitor } from "@capacitor/core";

import styles from '../../styles/Entry.module.scss';

function Entry() {
	const context = useContext(AppContext);

	return (
		<div className={`${styles.entry} ${Capacitor.isNativePlatform() ? styles.native : null}`}>
			<div>
				<img src={`https://surecode.fra1.cdn.digitaloceanspaces.com/${context.domain._id}/logo.svg`} alt={context.domain.name} />
			</div>
			<div>
				<Outlet />
			</div>
		</div>
	);
}

export default Entry;

import {useContext, useEffect, useState} from 'react';
import AppContext from "../../../context";

import Meta from "../../components/Meta";
import Header from "../../components/Header";
import Data from "../../../repository";
import Loading from "../../components/Loading";

import PinField from "react-pin-field";

import styles from '../../../styles/PersonalAccessCode.module.scss';

function PersonalAccessCode() {
    const context = useContext(AppContext);

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [personalAccessCode, setPersonalAccessCode] = useState(null);
    const [setting, setSetting] = useState(false);
    const [personalAccessCodeInput, setPersonalAccessCodeInput] = useState('');

    useEffect(async () => {
        setPersonalAccessCode(await fetchPersonalAccessCode());
        setLoading(false);
    }, [])

    const fetchPersonalAccessCode = async () => {
        const result = await Data.fetchUserById(context.user._id);
        return result.data.personalAccessCode;
    }

    const submit = async () => {
        try {
            setLoading(true);
            await Data.updatePersonalAccessCode(personalAccessCodeInput);
            setPersonalAccessCode(personalAccessCodeInput);
            setPersonalAccessCode(await fetchPersonalAccessCode());
            setError(false);
            setSetting(false);
            setLoading(false);
        } catch(error){
            setError(true);
            setLoading(false);
        }
    }

    if(loading) return <Loading button={false} />

    return (
        <>
            <Meta title="Personal Access Code" />

            <Header heading={"Personal Access Code"} />
            <main>
                <section>
                    <h2>Personal Access Code</h2>
                    <p>Use your personal access code when visiting our gym.</p>
                    <p>Codes must not consist of consecutive numbers.</p>

                    <div className={styles.personalAccessCode}>
                        {!setting ?
                            !personalAccessCode ?
                                <button className={'btn btnPrimary'} onClick={() => setSetting(true)}>Set Personal
                                    Access Code</button> : <div className={styles.existing}><p>{personalAccessCode}</p>
                                    <button className={'btn btnEmpty'} onClick={() => setSetting(true)}>Change
                                    </button>
                                </div>
                            :
                            <form onSubmit={submit}>
                                <div className={styles.pinFieldContainer}>
                                    <PinField length={4} validate="0123456789" inputMode={"numeric"}
                                              onChange={code => setPersonalAccessCodeInput(code)}/>
                                </div>
                                {error ? <p className={styles.error}>There was an error, please try again.</p> : null}
                                <div className={styles.actions}>
                                    <button className={'btn btnEmpty'} type={"button"}
                                            onClick={() => setSetting(false)}>Cancel
                                    </button>
                                    <button className="btn btnPrimary" disabled={loading}>
                                        {loading ?
                                            <Loading button={true}/>
                                            :
                                            `Save`
                                        }
                                    </button>
                                </div>
                            </form>
                        }
                    </div>
                </section>
            </main>
        </>
    );
}

export default PersonalAccessCode;

import InputWrapper from "./components/InputWrapper";
import {Link} from "react-router-dom";
import GoBack from "./components/GoBack";
import {useForm} from "react-hook-form";
import Repository from "../../repository";
import {useState} from "react";
import Meta from "../components/Meta";

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm()

    const submit = async (data) => {
        setLoading(true)

        try {
            const result = await Repository.login(data.email, data.password)
            console.log(result)
            localStorage.setItem("token", result.data.token);
            window.location.href = '/';

            setLoading(false)
            setError(false)
        } catch(error) {
            setLoading(false)
            setError(true)
        }
    }

    return (
        <>
            <Meta title={'Login'} />
            <GoBack to={"/"} label={"Back to Home"} />
            <form className={"w-96 mx-auto py-40 px-4 grid grid-cols-1 gap-4 rounded-lg"} onSubmit={handleSubmit(submit)}>
                <h1>Login</h1>
                <InputWrapper label={"Email Address"} input={<input type={"text"} {...register("email", { required: true })} />} error={errors.email?.type === "required" ? 'This field is required' : null} />
                <InputWrapper label={"Password"} input={<input type={"password"} {...register("password", { required: true })} />} error={errors.password?.type === "required" ? 'This field is required' : null} />
                <button type={"submit"} disabled={loading}
                      className={"inline-block bg-primary py-2 px-4 w-full lg:w-auto text-white text-center rounded-md mt-2 border-0"}>{loading ? '...' : 'Login'}</button>
                {error && <p className={"text-red-500"}>Invalid email or password</p>}
                <Link className={"text-sm text-textGrey"}>Forgot Password?</Link>
            </form>
        </>
    )
}

export default Login
import { useState, useEffect } from 'react';
import {Link, useParams} from 'react-router-dom';

import Loading from "../../components/Loading";

import Item from './components/Item';

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';
import moment from "moment";

function Existing() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [user , setUser] = useState(null);

    useEffect(async () => {
        await refreshUser();
    }, [])

    const refreshUser = async () => {
        setLoading(true);
        let user = await Data.fetchUserById(id);
        setUser(user.data);
        setLoading(false);
    }

    if(loading || !user) return <Loading button={false} />

    console.log(user)

    return (
        <>
            <div className={styles.form}>
                <div>
                    <p>First Name:</p>
                    <input type="text" value={user.firstName} disabled={true}/>
                </div>

                <div>
                    <p>Last Name:</p>
                    <input type="text" value={user.lastName} disabled={true}/>
                </div>

                <div>
                    <p>Email Address:</p>
                    <input type="text" value={user.email} disabled={true}/>
                </div>

                <div>
                    <p>Phone Number:</p>
                    <input type="text" value={user.phoneNumber} disabled={true}/>
                </div>

                <div>
                    <p>Verified:</p>
                    <input type="text" value={user.emailVerified ? 'Yes' : 'No'} disabled={true}/>
                </div>

                <div>
                    <p>Admin:</p>
                    <input type="text" value={user.admin ? 'Yes' : 'No'} disabled={true}/>
                </div>

                <div>
                    <p>Personal Access Code:</p>
                    <input type="text" value={user.personalAccessCode} disabled={true}/>
                </div>

                <div>
                    <p>Membership:</p>
                    <input type="text" value={`${user.memberships[0].plan.name} Renews on ${moment.unix(user.memberships[0].current_period_end).format('DD MMMM')} (${user.memberships[0].status})`} disabled={true}/>
                </div>
            </div>

            <div className={styles.payments}>
                <h2>Payments</h2>

                <div>
                    {user.payments.map((payment) => {
                        return (
                            <div key={payment._id}>
                                <p>{moment.unix(payment.period_start).format('DD MMM, HH:mm')}</p>
                                <p className={styles.status}>{payment.status}</p>
                                <p className={styles.amount}>£{(payment.amount_paid / 100).toFixed(2)}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default Existing;
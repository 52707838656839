import { useState } from 'react';
import AppContext from "../context";

import Router from "./Router";

import Data from '../repository';

const App = () => {
	const [domain, setDomain ] = useState();
	const [user, setUser] = useState();

	const fetchDomain = async () => {
		try {
			const domain = await Data.fetchDomain();
			setDomain(domain.data);
		} catch(error) {
			console.log(error)
		}
	}

	const fetchUser = async () => {
		const user = await Data.fetchUser();
		setUser(user.data);
	}

	const handle401 = () => {
		if(localStorage.getItem('token')) window.location.href='/log-out';
	}

	return (
		<AppContext.Provider value={{
			domain,
			user,
			fetchDomain,
			fetchUser,
			handle401,
		}}>
			<div style={{ '--primary-color': domain?.primaryColor, '--nav-bar-color': domain?.navBarColor, '--nav-bar-active-color': domain?.navBarActiveColor }}>
				<Router />
			</div>
		</AppContext.Provider>
	)
}

export default App;
import { Outlet } from "react-router-dom";

import Sidebar from './Sidebar';

import styles from '../../styles/Dashboard.module.scss';

function Admin() {
	return (
		<div className={styles.dashboard}>
			<Sidebar />
			<div className={styles.outlet}>
				<Outlet  />
			</div>
		</div>
	);
}

export default Admin;

import { useState, useEffect, useContext } from "react";
import { Link, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import AppContext from "../../../context";

import Loading from "../../components/Loading";
import Meta from "../../components/Meta";
import CreateBooking from "./CreateBooking.action";

import moment from 'moment';

import Data from '../../../repository';

import styles from '../../../styles/Booking.module.scss';

function Confirm() {
	const params = useParams();
	const navigate = useNavigate();
	const context = useContext(AppContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const [error, setError] = useState("");
	const [confirmed, setConfirmed] = useState(false);

	const [loading, setLoading] = useState(true);
	const [session, setSession] = useState();

	const start = searchParams.get('start');
	const end = searchParams.get('end');
	const shareLink = searchParams.get('shareLink');

	useEffect(async () => {
		if(!start || !end) exit();

		try {
			const session = await fetchSession(params.studio, start, end, shareLink);
			if(session.isAlreadyBooked) exit();
			setSession(session)
			setLoading(false);
		} catch(error) {
			exit();
		}
	}, []);

	const exit = () => {
		navigate(`/dashboard/book/${params.studio}`);
	}

	const fetchSession = async (studio, start, end, shareLink) => {
		const result = await Data.fetchSession(studio, start, end, shareLink);
		return result.data;
	}

	if(loading) return <Loading button={false} />;

	let available = true;
	if(session.booked && !session.shareLink) available = false;
	if(session.withheld) available = false;
	if(session.past) available = false;

	return (
		<>
			<Meta title="Confirm Booking" />

			<div className={styles.booking}>
				{confirmed && <p className={styles.success}>Your booking has been confirmed!</p>}
				{error && <p className={styles.error}>{error}</p>}

				{context.user.balance < 1 && !confirmed && <p className={styles.error}>You don't have enough tokens to create this booking. You can purchase tokens <Link to="/dashboard/tokens">here</Link>.</p>}

				<img src={require('../../../images/gym.svg').default} alt="" />

				{((session.booked || session.withheld || session.past) || session.shareLink) &&
					<div className={styles.message}>
						{session.past ?
							<p>It's too late to book this session.</p>
						: session.shareLink ?
							<p>This is a shared session.</p>
						:
							<p>This session is unavailable.</p>
						}
					</div>
				}

				<div className={styles.details}>
					<h1>{moment(session.start).format("ddd Do MMMM YYYY")} at {moment(session.start).format("HH:mm a")}</h1>
					<h1>{session.studio.name}</h1>
				</div>

				{(!confirmed && context.user.balance > 0) &&
					<div className={styles.actions}>
						<button className="btn btnEmpty" onClick={() => { navigate(-1) }}>Cancel</button>
						{available && <CreateBooking session={session} shareLink={shareLink} setError={setError} setConfirmed={setConfirmed} />}
					</div>
				}
			</div>
		</>
	)
}

export default Confirm;
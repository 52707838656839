import {ArrowRight} from "react-feather";
import GreyLink from "../account/components/GreyLink";
import {useParams} from "react-router-dom";
import classNames from "classnames";

const AvailableTime = ({ _id, event, setEvent, time, ticketsRemaining }) => {
    const soldOut = ticketsRemaining < 1

    return (
        <div onClick={!soldOut ? () => setEvent(_id) : null} className={classNames("flex justify-between items-center pl-4 p-2 border border-borderGrey rounded-md", event === _id ? 'border-primary' : '', soldOut ? '' : 'cursor-pointer')}>
            <p>{time}</p>
            <button onClick={() => setEvent(_id)} disabled={event === _id || soldOut} className={classNames("border-0 block md:inline-block bg-backgroundGrey px-3 py-2 rounded-md text-center")}>{soldOut ? 'Sold Out' : event === _id ? 'Selected' : 'Select'}</button>
        </div>
    )
}

export default AvailableTime
import { useState } from 'react';

import CancelBooking from "./CancelBooking.action";

import moment from 'moment';

import styles from '../../../styles/Bookings.module.scss';

function Booking({ _id, access, cancelled, shared, createdAt, session, shareLink, refreshBookings }) {

	const [show, setShow] = useState(false);
	const [invite, setInvite] = useState(false);

	const isInFuture = new Date() < new Date(session.start);

	const withinFortyEight = isInFuture && (new Date(session.start) < moment().add(48, 'hours').toDate());

	const [copied, setCopied] = useState(false);

	return (
		<div className={`${styles.booking} ${cancelled && styles.cancelled}`}>
			<div className={styles.details} onClick={() => {
				if(!cancelled) setShow(!show)
			}}>
				<div>
					<p>Date</p>
					<p>{moment(session.start).format("Do MMM YYYY")}</p>
				</div>
				<div>
					<p>Time</p>
					<p>{moment(session.start).format("HH:mm")} - {moment(session.end).format("HH:mm")}</p>
				</div>
				<div>
					<p>Studio</p>
					<p>{session.Studio.name}</p>
				</div>
				<div>
					<p>PIN</p>
					<p>{access.pin}</p>
				</div>
				{cancelled && <span className={styles.type}>Cancelled</span>}
				{shared && <span className={styles.type}>Shared</span>}
			</div>

			{show &&
				<div className={styles.actions}>
					<div className={`${invite ? styles.invite : "btn btnEmpty"}`} onClick={() => { !invite && setInvite(true) }}>
						Invite Friends

						{invite &&
							<div>
								<p>Send the following link to your friends to allow them to join your booking:</p>
								<div className={styles.link}>
									<p>{process.env.REACT_APP_BASE_URL}/dashboard/book/join/{shareLink}</p>
									<button className={'btn btnEmpty'} onClick={() => {
										navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/dashboard/book/join/${shareLink}`)
										setCopied(true)
									}
									}>{copied ? 'Copied' : '🔗 Copy Link'}</button>
								</div>
							</div>
						}
					</div>
					<CancelBooking id={_id} withinFortyEight={withinFortyEight} isInFuture={isInFuture} />
				</div>
			}
		</div>
	)
}

export default Booking;
import { useState, useContext } from 'react';
import AppContext from "../../../context";

import Loading from "../../components/Loading";

import Data from "../../../repository";

function CancelBooking({ id, withinFortyEight, isInFuture }) {
	const context = useContext(AppContext);

	const [loading, setLoading] = useState(false);

	if(!isInFuture) return null;

	const execute = async () => {
		if(withinFortyEight){
			if(!window.confirm('Cancelling a booking within 48 hours of the start time will result in your token being forfeited. Do you still want to cancel?')) return;
		} else {
			if(!window.confirm('Are you sure?')) return;
		}

		setLoading(true);
		await Data.cancelBooking(id);
		await context.fetchUser();
		window.location.reload();
	}

	return <button className="btn btnDanger" onClick={execute} disabled={loading}>
		{loading ? <Loading button={true} /> : `Cancel Booking`}
	</button>
}

export default CancelBooking;
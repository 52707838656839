import { Link } from "react-router-dom";

import moment from "moment";

import styles from "../../../../styles/Calendar.module.scss";

function Session({session, studio}) {
	const unavailable = session.withheld || session.booked || session.past;

	if(unavailable) {
		return <p className={`${styles.session} ${styles.unavailable}`}><strong>{moment(session.start).format('HH:mm')}</strong> {moment(session.start).format('a')}</p>
	} else {
		return (
			<Link to={`/dashboard/book/${studio}/confirm?start=${session.start}&end=${session.end}`} className={`${styles.session} ${unavailable ? styles.unavailable : null}`}>
				<p><strong>{moment(session.start).format('HH:mm')}</strong> {moment(session.start).format('a')}</p>
			</Link>
		)
	}
}

export default Session;
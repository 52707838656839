import GoBack from "../components/GoBack";
import CheckoutHeader from "../components/CheckoutHeader";
import {Outlet, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Data from "../Data";

const Checkout = () => {
    const { id } = useParams();
    if(!id) window.location.href = "/"

    const [experience, setExperience] = useState([]);

    useEffect(async () => {
        try {
            const experience = await Data.fetchExperienceById( { id})
            setExperience(experience.data)
        } catch (error) {
            window.location.href = "/"
        }
    }, []);

    return (
        <>
            <GoBack to={"/"} label={`Back to ${experience.name}`} />

            <CheckoutHeader />

            <Outlet />
        </>
    )
}

export default Checkout
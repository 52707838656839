const InputWrapper = ({ label, input, error }) => {
    return (
        <div>
            <div className={"grid grid-cols-1"}>
                <p className={"text-textGrey text-sm mb-1"}>{label}</p>
                {input}
            </div>
            {error ? <p className={"text-red-500 text-sm mt-2"}>{error}</p> : null}
        </div>
    )
}

export default InputWrapper
import { useContext, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import AppContext from "../../context";

import styles from '../../styles/Sidebar.module.scss';
import SidebarMembershipStatus from "../components/SidebarMembershipStatus";

function Sidebar() {
	const context = useContext(AppContext);

	const [open, setOpen] = useState(false);

	return (
		<div className={`${styles.sidebar} ${open ? styles.open : null}`}>
			<Link to="/">
				<img src={`https://surecode.fra1.cdn.digitaloceanspaces.com/${context.domain._id}/logo.svg`} alt={context.domain.name} />
			</Link>

			{context.domain.membershipMode ?
				<div className={styles.nav}>
					<p>Hey <strong>{context.user.firstName}</strong>, welcome to {context.domain.name}.</p>

					<SidebarMembershipStatus />

					<nav onClick={() => { setOpen(false) }}>
						<NavLink to="/dashboard/membership">Membership</NavLink>
						<NavLink to="/dashboard/personal-access-code">Personal Access Code</NavLink>
						<NavLink to="/dashboard/settings">Settings</NavLink>
						<NavLink to="/log-out">Log Out</NavLink>
					</nav>
				</div>
			:
				<div className={styles.nav}>
					<p>Hey <strong>{context.user.firstName}</strong>, you have <strong>{context.user.balance}</strong> tokens.</p>
					<nav onClick={() => { setOpen(false) }}>
						<NavLink to="/dashboard/book">New Booking</NavLink>
						<NavLink to="/dashboard/bookings">Manage Bookings</NavLink>
						<NavLink to="/dashboard/tokens">Purchase Tokens</NavLink>
						<NavLink to="/dashboard/settings">Settings</NavLink>
						<NavLink to="/log-out">Log Out</NavLink>
					</nav>
				</div>
			}

			<div id="hamburger" onClick={() => { setOpen(!open) }} className={open ? "close" : null}><span /></div>

			<span />
		</div>
	);
}

export default Sidebar;

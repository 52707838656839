import { useState, useEffect } from 'react';

import Loading from "../../components/Loading";

import Item from './components/Item';

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';

function List() {
	const [loading, setLoading] = useState(true);
	const [discountCodes, setDiscountCodes] = useState([]);

	useEffect(async () => {
		await refreshDiscountCodes();
	}, [])

	const refreshDiscountCodes = async () => {
		setLoading(true);
		let discountCodes = await Data.fetchDiscountCodes();
		setDiscountCodes(discountCodes.data);
		setLoading(false);
	}

	if(loading) return <Loading button={false} />

	return (
		<div className={styles.list}>
			{!discountCodes.length && <div><p>There aren't any discount codes to show.</p></div>}

			{discountCodes.map((discountCode) => {
				return <Item {...discountCode} />
			})}
		</div>
	)
}

export default List;
import { useContext, useState, useEffect } from 'react';
import AppContext from "../../../context";

import Product from "./Product";
import Loading from "../../components/Loading";

import Data from "../../../repository";

import styles from '../../../styles/Tokens.module.scss';
import RedeemVoucher from "./RedeemVoucher";
import {NavLink} from "react-router-dom";

function List() {
	const context = useContext(AppContext);

	const [loading, setLoading] = useState(true);
	const [products, setProducts] = useState([]);

	useEffect(async () => {
		setProducts(await fetchProducts());
		setLoading(false);
	}, [])

	const fetchProducts = async () => {
		const result = await Data.fetchPublicProducts();
		return result.data;
	}

	if(loading) return <Loading button={false} />

	return (
		<>
			<section className={styles.balance}>
				<h2>Balance</h2>
				<p>{context.user.balance} token{context.user.balance === 1 ? '' : 's'}</p>
			</section>

			<section>
				<h2>Purchase Tokens</h2>
				<div className={styles.products}>
					{!products.length && <div className={styles.none}><p>There aren't any products available for purchase, please try again later.</p></div>}

					{products.map((product) => {
						return <Product {...product} />
					})}
				</div>
			</section>

			<section>
				<h2>Redeem Gift Voucher</h2>
				<RedeemVoucher />
			</section>

			<NavLink to={'/gift-vouchers'} className={`btn btnPrimary linkBlock`}>Purchase Gift Vouchers</NavLink>
		</>
	);
}

export default List;

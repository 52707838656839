import GreyLink from "./GreyLink";

const Header = ({ heading, subHeading, button }) => {
    return (
        <div className={"p-6 container mx-auto flex items-center justify-between"}>
            <div>
                <h1 className={"text-2xl"}>{heading}</h1>
                {subHeading ? <p className={"text-textGrey"}>{subHeading}</p> : null}
            </div>

            {button}
        </div>
    )
}

export default Header
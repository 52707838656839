import {useContext, useState} from 'react';
import { Link } from "react-router-dom";
import Meta from "../../components/Meta";

import styles from '../../../styles/Entry.module.scss';
import Loading from "../../components/Loading";

import Data from '../../../repository';
import AppContext from "../../../context";

function Terms() {
	const context = useContext(AppContext);

	const [loading, setLoading] = useState(false);
	const [checkbox, setCheckbox] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		await Data.acceptTerms();
		window.location.href = '/';
	}

	return (
		<>
			<Meta title="Terms & Conditions" />

			<div className={styles.fullHeight}>
				<h1>{context.domain.termsDocumentName}</h1>

				<div className={styles.terms} dangerouslySetInnerHTML={{ __html: context.domain.terms }} />

				<div className={styles.actions}>
					<label className={styles.checkbox}>
						<input type="checkbox" value={checkbox} onChange={(e) => { setCheckbox(!checkbox) }} />
						I have read and accept the {context.domain.termsDocumentName}
					</label>
					<button className="btn btnPrimary" onClick={handleSubmit} disabled={!checkbox || loading}>
						{loading ?
							<Loading button={true} />
							:
							`Continue`
						}
					</button>
					<Link to="/entry/induction" className="btn btnEmpty">Back</Link>
				</div>
			</div>
		</>
	);
}

export default Terms;

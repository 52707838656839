import { useState } from 'react';

import Loading from "../../../components/Loading";

import Data from "../../../../repository";

function CancelBooking({ id }) {
	const [loading, setLoading] = useState(false);

	const execute = async () => {
		if(!window.confirm('Are you sure?')) return;

		setLoading(true);
		await Data.cancelBooking(id);
		window.location.reload();
	}

	return <button className="btn btnEmpty" onClick={execute} disabled={loading}>
		{loading ? <Loading button={true} /> : `Cancel Booking`}
	</button>
}

export default CancelBooking;
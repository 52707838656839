
import Header from "./components/Header";
import AccountSection from "./components/AccountSection";
import GreyLink from "./components/GreyLink";
import GoBack from "../components/GoBack";
import SplitColumns from "./components/SplitColumns";
import InputWrapper from "../components/InputWrapper";
import {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Repository from "../../../repository";
import Data from "../../../repository";
import Error from "../admin/components/Error";
import AppContext from "../../../context";

const Booking = () => {
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    const { register, handleSubmit, formState: { errors }, reset } = useForm()

    useEffect(() => {
        reset(context.user)
    }, [context.user]);

    const submit = async (data) => {
        setLoading(true)

        try {
            await Repository.updateUser(data)

            window.location.href = `/account`;

            setLoading(false)
            setError(false)
        } catch(error) {
            setLoading(false)
            setError(true)
        }
    }

    return (
        <>
            <GoBack to={"/account"} label={"Back to My Account"}/>

            <Header heading={'Update Details'}/>

            <form className={"container mx-auto p-6 pt-0"} onSubmit={handleSubmit(submit)}>
                <div className={"grid grid-cols-1 gap-4 lg:grid-cols-2"}>
                    <InputWrapper label={"First Name"} input={
                        <input type={"text"} {...register('firstName', { required: true })} />
                    } error={errors.firstName ? 'This is a required field' : null}/>

                    <InputWrapper label={"Last Name"} input={
                        <input type={"text"} {...register('lastName', { required: true })} />
                    } error={errors.lastName ? 'This is a required field' : null}/>

                    <InputWrapper label={"Phone Number"} input={
                        <input type={"text"} {...register('phoneNumber', { required: true })} />
                    } error={errors.phoneNumber ? 'This is a required field' : null}/>
                </div>

                <button
                    disabled={loading}
                    className={"bg-primary py-2 px-4 w-full lg:w-auto text-white text-center rounded-md mt-6 border-0"}>
                    {loading ? 'Updating Details...' : 'Confirm Changes'}
                </button>

                {error ? <Error message={"An error occurred when updating your account details, please try again"} /> : null }
            </form>
        </>
    )
}

export default Booking
import { Link } from 'react-router-dom';

import styles from '../../../../styles/Admin.module.scss';

function Item({ name, _id, User }) {
	return (
		<Link to={_id} className={styles.user}>
			<p>{name} (Related to {User.firstName} {User.lastName})</p>
		</Link>
	)
}

export default Item;
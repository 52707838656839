import {useContext, useState} from "react";
import AppContext from "../../../context";

import Loading from "../../components/Loading";

import Data from "../../../repository";

import styles from '../../../styles/Tokens.module.scss';


function RedeemVoucher() {
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [voucher, setVoucher] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const submit = async e => {
        e.preventDefault();
        setSuccess(false)
        setError('');
        setLoading(true);

        try {
            await Data.redeemGiftVoucher(voucher)
            setSuccess(true)
            setVoucher("")
            await context.fetchUser();
        } catch(error){
            setError(error.response.data.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <form className={styles.redeem} onSubmit={submit}>
            {success && <p className={styles.message}>Your voucher has been successfully redeemed.</p>}
            {error && <p className={styles.message}>{error}</p>}

            <div>
                <input type={'text'} placeholder={'Voucher Code'} value={voucher} onChange={e => setVoucher(e.target.value)} maxLength={12} required={true} />

                <button className="btn btnPrimary" disabled={loading || voucher.length !== 12}>
                    {loading ?
                        <Loading button={true} />
                        :
                        `Redeem`
                    }
                </button>
            </div>
        </form>
    )
}

export default RedeemVoucher
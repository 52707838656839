import {NavLink, useParams} from "react-router-dom";
import {Download, Plus, Trash2} from "react-feather";
import Empty from "./Empty";
import {useEffect, useState} from "react";
import Data from "../../Data";
import moment from "moment";

const ExperienceEvents = () => {

    const { id } = useParams();

    const [events, setEvents] = useState([]);

    useEffect(async () => {
        try {
            const events = await Data.fetchEventsByExperienceId({ id })
            setEvents(events.data)
        } catch(error){
            window.location.href = '/admin/experiences'
        }
    }, []);

    if(events.length === 0) return (
        <Empty>
            <p>No Events</p>
            <NavLink to={`/admin/experiences/${id}/add-events`}
                     className={"bg-backgroundGrey border flex items-center gap-1 px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}><Plus
                size={20}/> Add Events</NavLink>
        </Empty>
    )

    return (
        <div
            className={"w-full border-collapse outline outline-1 outline-gray-200 rounded-lg overflow-hidden drop-shadow-sm"}>
            <div className={"bg-white p-6 flex items-center justify-between border-b"}>
                <p className={"text-lg"}>Events</p>
                <NavLink to={`/admin/experiences/${id}/add-events`}
                         className={"bg-backgroundGrey border flex items-center gap-1 px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}><Plus
                    size={20}/> Add Events</NavLink>
            </div>

            <table className={"w-full"}>
                <thead className={"bg-gray-50"}>
                <tr>
                    <th className={"text-left px-6 py-4"}>Date &amp; Time</th>
                    <th className={"text-left px-6 py-4"}>Tickets</th>
                    <th className={"text-left px-6 py-4"}>Status</th>
                    <th className={"text-left px-6 py-4"}>Actions</th>
                </tr>
                </thead>
                <tbody className={"bg-white"}>
                {events.map((event, index) => (
                    <tr className={"border-t"}>
                        <td className={"px-6 py-4"}>{moment(event.date).format('MMM Do YYYY, H:mm A')}</td>
                        <td className={"px-6 py-4"}>{event.totalTickets === 0 ? '-' : `${event.totalTickets} ticket${event.totalTickets > 1 ? 's' : ''} (${event.bookings.length} booking${event.bookings.length > 1 ? 's' : ''})`}</td>
                        <p className={"px-6 py-4"}>{event.ticketsRemaining > 0 ? `${event.ticketsRemaining} tickets remaining` : 'Sold Out'}</p>
                        <td className={"px-6 py-2"}>
                            <div className={"flex gap-2"}>
                            <NavLink to={`/admin/upcoming-events/${event._id}`}
                                         className={"bg-backgroundGrey px-2.5 py-1 rounded hover:opacity-80 transition"}>View</NavLink>
                            </div>
                        </td>
                    </tr>
                ))}

                </tbody>
            </table>
        </div>
    )
}

export default ExperienceEvents
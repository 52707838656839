import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Loading from "../../components/Loading";

import Item from './components/Item';

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';

function List() {
	const [loading, setLoading] = useState(true);
	const [minors, setMinors] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(async () => {
		await refreshUsers();
	}, [])

	const refreshUsers = async () => {
		setLoading(true);
		const minors = await Data.fetchMinors();
		setMinors(minors.data);
		setLoading(false);
	}

	if(loading) return <Loading button={false} />

	return (
		<div className={styles.list}>
			{!minors.length && <div><p>There aren't any minors to show.</p></div>}

			{minors.map((minor) => {
				return <Item {...minor} />
			})}
		</div>
	)
}

export default List;
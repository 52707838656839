import {useState} from "react";
import {ChevronDown} from "react-feather";

const Accordion = ({ heading, defaultOpen, children }) => {
    const [open, setOpen] = useState(defaultOpen);

    return (
        <div className={"py-4 border-b border-b-borderGrey"}>
            <div className={"flex justify-between items-center cursor-pointer"} onClick={() => !defaultOpen ? setOpen(!open) : null}>
                <p>{heading}</p>
                {!defaultOpen ? <ChevronDown className={open ? "rotate-180" : null} /> : null }
            </div>
            {open ? <div className={"mt-2"}>{children}</div> : null}
        </div>
    )
}

export default Accordion
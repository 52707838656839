import Header from "./components/Header";
import {Outlet} from "react-router-dom";
import Footer from "./components/Footer";

const Layout = () => {
    return(
        <div className={"bg-white [&_*]:font-sans"}>
            <Header/>
            <Outlet/>
            <Footer/>
        </div>
    )
}

export default Layout
import {Link, Outlet} from 'react-router-dom';

import List from './List';
import Existing from './Existing';
import Create from './Create';

import Meta from "../../components/Meta";

import styles from "../../../styles/Admin.module.scss";
import Header from "../../components/Header";

function Template() {
	return (
		<>
			<Meta title="Bookings" />

			<Header heading={"Manage Bookings"} button={<Link to="create" className="btn btnPrimary">+ Create Booking</Link>} />

			<main>
				<Outlet />
			</main>
		</>
	)
}

export default {
	Template,
	List,
	Existing,
	Create
};
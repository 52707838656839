import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import Loading from "../../../components/Loading";
import UpdateMembershipPlanAction from "../actions/UpdateMembershipPlan.action";

import Data from '../../../../repository';

import styles from '../../../../styles/Admin.module.scss';

function UpdateMembershipPlanForm(){
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [membershipPlan, setMembershipPlan] = useState(null);

	useEffect(async () => {
		await fetchMembershipPlan();
	}, [])

	const fetchMembershipPlan = async () => {
		setLoading(true);
		const membershipPlan = await Data.fetchMembershipPlan(id);
		console.log(membershipPlan.data)
		setMembershipPlan(membershipPlan.data);
		setLoading(false);
	}

	const updateField = (e) => {
		setMembershipPlan({
			...membershipPlan,
			[e.target.name]: e.target.value
		});
	}

	if(loading) return <Loading button={false} />

	return (
		<div className={styles.form}>
			<div>
				<p>Name:</p>
				<input type="text" name="name" value={membershipPlan.name} onChange={updateField}/>
			</div>

			<div>
				<p>Description:</p>
				<input type="text" name="description" value={membershipPlan.description} onChange={updateField}/>
			</div>

			<div>
				<p>Amount:</p>
				<input type="number" name="amount" value={membershipPlan.amount} onChange={updateField}/>
			</div>

			<div>
				<p>Interval:</p>
				<div>
					<input type={'number'} name={'interval_count'} value={membershipPlan.interval_count}
						   onChange={updateField} min={0} max={365}/>
					<select name="interval" value={membershipPlan.interval} onChange={updateField}>
						<option value="day">Days</option>
						<option value="week">Weeks</option>
						<option value="month">Months</option>
						<option value="year">Years</option>
					</select>
				</div>
			</div>

			<div>
				<p>Lock ID:</p>
				<input type="text" name="lockstateLockId" value={membershipPlan.lockstateLockId}
					   onChange={updateField}/>
			</div>

			<div>
				<p>Secondary Lock ID:</p>
				<input type="text" name="secondaryLockstateLockId" value={membershipPlan.secondaryLockstateLockId}
					   onChange={updateField}/>
			</div>

			<div>
				<p>Schedule:</p>
				<input type="text"
					   value={`https://smartlock.devicewebmanager.com/access/schedules/${membershipPlan.lockstateAccessScheduleId}/edit`}/>
			</div>

			<select name="active" value={membershipPlan.active} onChange={updateField}>
				<option value="true">Enabled</option>
				<option value="false">Disabled</option>
			</select>

			<UpdateMembershipPlanAction {...membershipPlan} />
		</div>
	)
}

export default UpdateMembershipPlanForm;
import {Link} from "react-router-dom";
import moment from "moment";

const BookingPreview = ({booking: { _id, reference, Event, tickets, totalPrice }}) => {
    return (
        <Link to={`/account/bookings/${_id}`} className={"bg-backgroundGrey p-4 rounded-md flex justify-between"}>
            <div>
                <p>{Event.Experience.name}</p>
                <p className={"text-sm text-textGrey"}>{moment(Event.date).format("MMM Do YYYY, H:mm A")} for {tickets} {tickets > 1 ? "people" : "person"}</p>
            </div>

            <div className={"text-right"}>
                <p>{reference}</p>
                <p className={"text-sm text-textGrey"}>&pound;{totalPrice}</p>
            </div>
        </Link>
    )
}

export default BookingPreview
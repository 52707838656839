import {Link, Outlet} from 'react-router-dom';

import List from './List';
import CreateStudioForm from './forms/CreateStudio.form';
import UpdateStudioForm from './forms/UpdateStudio.form';

import Meta from "../../components/Meta";

import styles from '../../../styles/Admin.module.scss';
import Header from "../../components/Header";

function Template() {
	return (
		<>
			<Meta title="Studios" />

			<Header heading={"Studios"} button={<Link to="create" className="btn btnPrimary">+ Create Studio</Link>} />

			<main>
				<Outlet />
			</main>
		</>
	)
}

export default {
	Template,
	List,
	Create: CreateStudioForm,
	Update: UpdateStudioForm
};
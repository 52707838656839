import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Calendar from "./calendar/Calendar";
import Meta from "../../components/Meta";

import Data from "../../../repository";

function Book(){

	const navigate = useNavigate();
	const params = useParams();

	const [studios, setStudios] = useState([]);

	useEffect(async () => {
		setStudios(await fetchStudios());
	}, []);

	useEffect(async () => {
		if(studios.length){
			let studio = params.studio ? params.studio : studios[0]._id;
			let week = params.week ? parseInt(params.week) : 0;

			if(!studios.map((studio) => { return studio._id }).includes(studio)) studio = studios[0]._id;
			if(week < 0 || 3 < week || !Number.isInteger(week)) week = 0;

			if(params.studio !== studio || parseInt(params.week) !== parseInt(week)) navigate(`${studio}/sessions/${week}`);
		}
	}, [studios, params])

	const fetchStudios = async () => {
		const result = await Data.fetchStudios();
		return result.data;
	}

	const setStudio = (studio) => {
		navigate(`${studio}/sessions/${params.week}`);
	}

	const setWeek = (week) => {
		week = parseInt(week);
		navigate(`${params.studio}/sessions/${week}`);
	}

	if(!studios.length || !params.studio || !params.week) return null;

	return (
		<>
			<Meta title="New Booking" />

			<Calendar
				studio={params.studio}
				setStudio={setStudio}
				week={parseInt(params.week)}
				setWeek={setWeek}
				studios={studios}
			/>
		</>
	)
}

export default Book;
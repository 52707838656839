import Sidebar from "./components/Sidebar";
import {Outlet} from "react-router-dom";

const Layout = () => {
    return(
        <div className={"bg-white [&_*]:font-sans flex items-start"}>
            <Sidebar />
            <main className={"w-full min-h-dvh bg-backgroundGrey ml-72"}>
                <Outlet />
            </main>
        </div>
    )
}

export default Layout
import {Link} from "react-router-dom";
import {useContext} from "react";
import AppContext from "../../../context";

const Footer = () => {
    const context = useContext(AppContext);

    return <div className={"border-t border-borderGrey p-4 flex flex-wrap justify-between flex-row gap-1 sm:gap-0"}>
        <p className={"text-sm text-textGrey"}>&copy; {new Date().getFullYear()} {context.domain.name}</p>
        <Link to={""} className={"text-sm text-textGrey"}>Privacy Policy</Link>
    </div>
}

export default Footer
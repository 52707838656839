import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Select from 'react-select';

import CreateTransactionAction from "../actions/CreateTransaction.action";

import Loading from "../../../components/Loading";

import Data from "../../../../repository";

import styles from '../../../../styles/Admin.module.scss';

function CreateTransactionForm(){
	const [searchParams, setSearchParams] = useSearchParams();
	const [users, setUsers] = useState([]);
	const [loading, setLoading] = useState(true);
	const [transaction, setTransaction] = useState({
		user: "",
		adjustment: 0,
		note: ""
	});

	useEffect(async () => {
		const users = await Data.fetchUsers();
		if(searchParams.get("user")){
			let selected = users.data.filter((user) => {
				return user._id === searchParams.get("user");
			})[0]
			selected = `${selected.firstName} ${selected.lastName} (${selected.email})`
			setTransaction({
				...transaction,
				user: {
					value: searchParams.get("user"),
					label: selected
				}
			})
		}
		setUsers(users.data);
		setLoading(false);
	}, []);

	const updateField = (e) => {
		setTransaction({
			...transaction,
			[e.target.name]: e.target.value
		});
	}

	if(loading) return <Loading button={false} />

	return (
		<div className={styles.form}>
			<div>
				<p>User:</p>
				<Select className={styles.select} value={transaction.user} options={users.map((user) => {
					return {
						value: user._id,
						label: `${user.firstName} ${user.lastName} (${user.email})`
					}
				})} onChange={(e) => setTransaction({
					...transaction,
					user: e
				})} defaultValue={transaction.user} />
			</div>

			<div>
				<p>Tokens:</p>
				<input type="number" name="adjustment" value={transaction.adjustment} onChange={updateField} />
			</div>

			<div>
				<p>Notes:</p>
				<input type="text" name="note" value={transaction.note} onChange={updateField} />
			</div>

			<CreateTransactionAction transaction={{
				...transaction,
				user: transaction.user.value
			}} />
		</div>
	)
}

export default CreateTransactionForm;
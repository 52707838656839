import { useContext, useState } from "react";
import AppContext from "../../../context";

import { Link, useNavigate } from 'react-router-dom';

import Meta from "../../components/Meta";

import Data from "../../../repository";

import styles from '../../../styles/Entry.module.scss';
import Loading from "../../components/Loading";

function RequestInduction() {
	const context = useContext(AppContext);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [message, setMessage] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		await Data.requestInduction({
			firstName: context.user.firstName,
			lastName: context.user.lastName,
			email: context.user.email,
			phoneNumber,
			message
		});

		navigate("/entry/terms");
	}

	return (
		<>
			<Meta title="Request Face-to-Face Induction" />

			<div className={styles.fullHeight}>
				<div>
					<h1>Request Face-to-Face Induction</h1>
					<p>Please enter your contact details below, and give an induction of when you would like your induction to take place.</p>

					<div className={styles.form}>
						<input type="text" value={context.user.email} disabled={true} />
						<input type="text" placeholder="Phone Number" value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} disabled={loading} />
						<textarea placeholder="What time of the week would you prefer?" value={message} onChange={(e) => { setMessage(e.target.value) }} rows={5} />
					</div>
				</div>

				<div className={styles.actions}>
					<Link to="/entry/terms" onClick={handleSubmit} className="btn btnPrimary" disabled={loading}>
						{loading ?
							<Loading button={true} />
							:
							`Request Induction`
						}
					</Link>
				</div>
			</div>
		</>
	);
}

export default RequestInduction;

import Header from "../components/Header";
import {Delete, Download, Edit2, Edit3, Plus, Trash, Trash2} from "react-feather";
import {NavLink, useParams} from "react-router-dom";
import InputWrapper from "../../components/InputWrapper";
import Form from "../components/Form";
import {useEffect, useState} from "react";
import Data from "../../Data";
import {useForm} from "react-hook-form";
import ExperienceImageUpload from "../components/ExperienceImageUpload";
import Meta from "../../../components/Meta";

const Edit = () => {
    const { id } = useParams();

    const [experience, setExperience] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    useEffect(async () => {
        try {
            const experience = await Data.fetchExperienceById({ id })
            setExperience(experience.data)
            reset(experience.data)
        } catch(error){
            window.location.href = '/admin/experiences'
        } finally {
            setLoading(false)
        }
    }, []);

    const { register, handleSubmit, formState: { errors }, reset } = useForm()

    const submit = async (data) => {
        setLoading(true)

        try {
            const result = await Data.updateExperience({ id, experience: data })
            window.location.href = '/admin/experiences/' + result.data._id;

            setLoading(false)
            setError(false)
        } catch(error) {
            setLoading(false)
            setError(true)
        }
    }

    return (
        <>
            <Meta title={`Edit ${experience.name}`} />
            <Header heading={"Edit Details"} backToLabel={experience.name} backToTo={`/admin/experiences/${id}`} />
            <div className={"p-6 grid grid-cols-1 gap-6"}>

                <Form heading={"Listing Details"} onSubmit={handleSubmit(submit)}>

                    <InputWrapper label={"Name"} input={<input type={"text"} {...register('name', { required: true })} />} error={errors.name ? "This field is required" : null} />
                    <InputWrapper label={"Published"} input={<select {...register('isPublished', )}>
                        <option value={"true"}>Published</option>
                        <option value={"false"}>Unpublished</option>
                    </select>} />
                    <InputWrapper label={"Short Description"} input={<input type={"text"} {...register('shortDescription')} />}/>
                    <InputWrapper label={"Description"} input={<textarea {...register('description', )} />}/>
                    <InputWrapper label={"Additional Details"} input={<textarea {...register('additionalDetails', )} />}/>
                    <InputWrapper label={"Cancellation Policy"} input={<textarea {...register('cancellationPolicy', )} />}/>
                    <InputWrapper label={"Location"} input={<input type={"text"} {...register('location')} />}/>
                    <InputWrapper label={"Duration (Minutes)"} input={<input type={"number"} {...register('duration', { required: true })} />} error={errors.duration ? "This field is required" : null} />
                    <InputWrapper label={"Price"} input={<input type={"number"} {...register('price', { required: true })} />}/>
                    <InputWrapper label={"Refund Window (Hours)"} input={<input type={"number"} {...register('refundWindow', { required: true })} />} error={errors.refundWindow ? "This field is required" : null} />
                    <InputWrapper label={"Minimum People"} input={<input type={"number"} {...register('minPeople', { required: true })} />} error={errors.minPeople ? "This field is required" : null} />
                    <InputWrapper label={"Maximum People"} input={<input type={"number"} {...register('maxPeople', { required: true })} />} error={errors.maxPeople ? "This field is required" : null} />

                    <div className={"mt-2 flex gap-2"}>
                        <button type={"submit"} disabled={loading}
                                 className={"bg-backgroundGrey border px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>{loading ? "Saving..." : "Save Changes"}</button>
                    </div>
                </Form>

                <ExperienceImageUpload />
            </div>
        </>
    )
}

export default Edit
import { NavLink, useLocation } from "react-router-dom";

import styles from '../../styles/Tabs.module.scss';

function Tabs() {
	const location = useLocation();

	return (
		<div className={styles.tabs}>
			<NavLink to="/dashboard/book" activeClassName={"active"}>
				{location.pathname.includes("/dashboard/book/") || location.pathname === "/dashboard/book" ?
					<img src={require('../../images/plus-blue.svg').default} alt={""} />
				:
					<img src={require('../../images/plus-black.svg').default} alt={""} />
				}
				<p>Book</p>
			</NavLink>

			<NavLink to="/dashboard/bookings" activeClassName={"active"}>
				{location.pathname.includes("/dashboard/bookings") ?
					<img src={require('../../images/bookings-blue.svg').default} alt={""} />
					:
					<img src={require('../../images/bookings-black.svg').default} alt={""} />
				}
				<p>Bookings</p>
			</NavLink>

			<NavLink to="/dashboard/tokens" activeClassName={"active"}>
				{location.pathname.includes("/dashboard/tokens") ?
					<img src={require('../../images/wallet-blue.svg').default} alt={""} />
					:
					<img src={require('../../images/wallet-black.svg').default} alt={""} />
				}
				<p>Tokens</p>
			</NavLink>

			<NavLink to="/dashboard/settings" activeClassName={"active"}>
				{location.pathname.includes("/dashboard/settings") ?
					<img src={require('../../images/person-blue.svg').default} alt={""} />
					:
					<img src={require('../../images/person-black.svg').default} alt={""} />
				}
				<p>Settings</p>
			</NavLink>
		</div>
	);
}

export default Tabs;

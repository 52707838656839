import { useContext, useState } from "react";
import AppContext from "../../../context";

import Loading from "../../components/Loading";

import Data from "../../../repository";

function CreateBooking({ session, shareLink, setError, setConfirmed }) {
	const context = useContext(AppContext);

	const [loading, setLoading] = useState(false);

	const execute = async () => {
		try {
			setError("");
			setLoading(true);
			await Data.createBooking(session, shareLink);
			setLoading(false);
			setConfirmed(true);
			await context.fetchUser();
		} catch(error){
			setError(error.response.data.message)
			setLoading(false)
		}
	}

	return (
		<button onClick={execute} className="btn btnPrimary" disabled={loading}>
			{loading ?
				<Loading button={true} />
				:
				`Book Now`
			}
		</button>
	)
}

export default CreateBooking;
import {useContext, useEffect, useState} from 'react';
import AppContext from "../../../context";

import Data from "../../../repository";
import Loading from "../../components/Loading";

import styles from '../../../styles/CurrentMembership.module.scss';
import moment from "moment";

function CurrentMembership() {
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(true);
    const [memberships, setMemberships] = useState([]);

    useEffect(async () => {
        setMemberships(await fetchMemberships());
        setLoading(false);
    }, [])

    const fetchMemberships = async () => {
        const result = await Data.fetchMemberships();
        return result.data;
    }

    const redirectToBillingPortal = async (flowType) => {
        setLoading(true)
        const result = await Data.fetchBillingPortalSession(flowType);
        window.location.href = result.data.url;
    }

    if(loading) return <section><Loading button={false} /></section>

    const currentMembership = memberships[memberships.length - 1]

    return (
        <>
            <section className={styles.status}>
                {currentMembership.status === "active" ?
                    !currentMembership.cancel_at_period_end ?
                        <p>Your membership is currently <strong>active</strong> and will renew on <strong>{moment(currentMembership.current_period_end * 1000).format('Do MMMM')}</strong>.</p>
                    :
                        <>
                            <p>Your membership has been <strong>cancelled</strong>, however it will remain active until
                                it expires
                                on <strong>{moment(currentMembership.current_period_end * 1000).format('Do MMMM')}</strong>.
                            </p>
                            <button className={'btn btnPrimary'}
                                    onClick={() => redirectToBillingPortal(undefined)}>Reactivate
                            </button>

                        </>
                    : currentMembership.status === "past_due" ?
                        <>
                            <p>Your latest <strong>payment has failed</strong>, update your payment method to continue your
                                membership.</p>
                            <button className={'btn btnPrimary'}
                                    onClick={() => redirectToBillingPortal(undefined)}>Resolve
                            </button>
                        </>
                    : ''
                }
            </section>

            <section>
            <h2>Membership Plan</h2>

                <div className={styles.plan}>
                    <div className={styles.header}>
                        <h3>{currentMembership.plan.name}</h3>
                        <div className={styles.price}>
                            <h2>£{(currentMembership.plan.amount / 100).toFixed(2)}</h2>
                            <p>/ {currentMembership.plan.interval_count} {currentMembership.plan.interval}{currentMembership.plan.interval_count > 1 ? 's' : ''}</p>
                        </div>
                    </div>
                    <p>{currentMembership.plan.description}</p>
                </div>

                <button className={'btn btnEmpty'} onClick={() => redirectToBillingPortal(undefined)}>Manage Subscription</button>
            </section>

            <section>
                <h2>Payment Method</h2>

                <div className={styles.paymentMethod}>
                    <div>
                        <p>Card Number</p>
                        <p>**** **** **** {currentMembership.paymentMethod.card.last4}</p>
                    </div>
                    <div>
                        <p>Expiry Date</p>
                        <p>{currentMembership.paymentMethod.card.exp_month}/{currentMembership.paymentMethod.card.exp_year}</p>
                    </div>
                </div>

                <button className={'btn btnEmpty'}
                        onClick={() => redirectToBillingPortal('payment_method_update')}>Update Payment Method
                </button>
            </section>
        </>
    );
}

export default CurrentMembership;

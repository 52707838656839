import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import moment from "moment";

import Loading from "../../components/Loading";
import CancelBooking from "./actions/CancelBooking.action";

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';

function Existing(){
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [booking, setBooking] = useState(null);

	useEffect(async () => {
		await fetchBooking();
	}, [])

	const fetchBooking = async () => {
		setLoading(true);
		const booking = await Data.fetchBooking(id);
		setBooking(booking.data);
		setLoading(false);
	}

	if(loading) return <Loading button={false} />

	return (
		<>
			{booking.cancelled && <span className={styles.cancelled}>Cancelled Booking</span>}
			{booking.shared && <span className={styles.shared}>Shared</span>}

			<div className={styles.form}>
				<div>
					<p>User</p>
					<input type="text" value={`${booking.User.firstName} ${booking.User.lastName} (${booking.User.email})`} disabled={true} />
				</div>
				<div>
					<p>Date</p>
					<input type="text" value={moment(booking.session.start).format("Do MMM YYYY")} disabled={true} />
				</div>
				<div>
					<p>Time</p>
					<input type="text" value={moment(booking.session.start).format("HH:mm")} disabled={true} />
				</div>
				<div>
					<p>Studio</p>
					<input type="text" value={booking.session.Studio.name} disabled={true} />
				</div>
				<div>
					<p>PIN</p>
					<input type="text" value={booking.access.pin} disabled={true} />
				</div>
				<div>
					<p>Booked At</p>
					<input type="text" value={moment(booking.createdAt).format('YYYY-MM-DD HH:mm:ss')} disabled={true} />
				</div>
			</div>

			<div className={styles.actions}>
				{!booking.cancelled &&
					<>
						<CancelBooking id={booking._id} />
						<button className="btn btnEmpty" onClick={() => { navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL}/dashboard/book/join/${booking.shareLink}`) }}>Copy ShareLink</button>
					</>
				}
			</div>
		</>
	)
}

export default Existing;
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "../../../components/Loading";

import Data from "../../../../repository";

function CreateMembershipPlanAction(membershipPlan) {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const execute = async () => {
		setLoading(true);
		await Data.createMembershipPlan(membershipPlan);
		setLoading(false);
		navigate("/admin/membership-plans")
	}

	return (
		<button onClick={execute} className="btn btnPrimary" disabled={loading}>
			{loading ?
				<Loading button={true} />
				:
				`Create Membership Plan`
			}
		</button>
	)
}

export default CreateMembershipPlanAction;
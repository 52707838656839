import { useContext, useState } from 'react';
import {Link, NavLink} from 'react-router-dom';
import AppContext from "../../context";

import styles from '../../styles/Sidebar.module.scss';
import {Capacitor} from "@capacitor/core";

function Sidebar() {
	const context = useContext(AppContext);

	const [open, setOpen] = useState(false);

	return (
		<div className={`${styles.sidebar} ${open ? styles.open : null} ${Capacitor.isNativePlatform() ? styles.native : styles.native}`}>
			<Link to="/">
				<img src={`https://surecode.fra1.cdn.digitaloceanspaces.com/${context.domain._id}/logo.svg`} alt={context.domain.name} />
			</Link>

			{context.domain.membershipMode ?
				<div className={styles.nav}>
					<nav onClick={() => { setOpen(false) }}>
						<NavLink to="/admin/members">Members</NavLink>
						<NavLink to="/admin/membership-plans">Membership Plans</NavLink>
						<NavLink to="/admin/access-history">Access History</NavLink>
						<NavLink to="/admin/coupons">Coupons</NavLink>
						<NavLink to="/log-out">Log Out</NavLink>
					</nav>
				</div>
				:
				<div className={styles.nav}>
					<nav onClick={() => { setOpen(false) }}>
						<NavLink to="/admin/bookings">Manage Bookings</NavLink>
						<NavLink to="/admin/products">Pricing</NavLink>
						<NavLink to="/admin/users">Users</NavLink>
						<NavLink to="/admin/minors">Minors</NavLink>
						<NavLink to="/admin/transactions">Transactions</NavLink>
						<NavLink to="/admin/studios">Studios</NavLink>
						<NavLink to="/admin/discount-codes">Discount Codes</NavLink>
						<NavLink to="/log-out">Log Out</NavLink>
					</nav>
				</div>
			}

			<div id="hamburger" onClick={() => { setOpen(!open) }} className={open ? "close" : null}><span /></div>

			<span />
		</div>
	);
}

export default Sidebar;

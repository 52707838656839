import {Routes, Route, Navigate} from 'react-router-dom';
import Layout from "./Layout";
import SiteSettings from "./SiteSettings";
import Experiences from "./experiences/Experiences";
import Bookings from "./bookings/Bookings";
import UpcomingEvents from "./upcomingEvents/UpcomingEvents";
import Experience from "./experiences/Experience";
import AddEvents from "./experiences/AddEvents";
import Edit from "./experiences/Edit";
import Booking from "./bookings/Booking";
import Event from "./upcomingEvents/Event";
import Create from "./experiences/Create";

const ExperiencesAdminRouter = () => {
    return <Routes>
        <Route path="/" element={<Layout />}>
            <Route path="/upcoming-events/:id" element={<Event />} />
            <Route path="/upcoming-events" element={<UpcomingEvents />} />
            <Route path="/site-settings" element={<SiteSettings />} />
            <Route path="/experiences/create" element={<Create />} />
            <Route path="/experiences/:id/add-events" element={<AddEvents />} />
            <Route path="/experiences/:id/edit" element={<Edit />} />
            <Route path="/experiences/:id" element={<Experience />} />
            <Route path="/experiences" element={<Experiences />} />
            <Route path="/bookings/:id" element={<Booking />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/" element={<Navigate to="/admin/upcoming-events" />} />
        </Route>
    </Routes>
}

export default ExperiencesAdminRouter
import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppContext from "../../../context";

import moment from 'moment';

import Meta from "../../components/Meta";
import Loading from "../../components/Loading";
import Header from "../../components/Header";
import Marketing from "../../components/Marketing";
import IssueReport from "../../components/IssueReport";

import Data from '../../../repository';

import styles from '../../../styles/Account.module.scss';
import DeleteAccount from "../../components/DeleteAccount";

function Account() {
	const context = useContext(AppContext);

	const [loading, setLoading] = useState(true);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [minors, setMinors] = useState([]);

	useEffect(async () => {
		setFirstName(context.user.firstName);
		setLastName(context.user.lastName);
		setPhoneNumber(context.user.phoneNumber);
		setLoading(false);

		const minors = await Data.fetchMinors();
		setMinors(minors.data);
	}, context.user);

	const updateUser = async (e) => {
		e.preventDefault();
		setLoading(true);

		await Data.updateUser({
			firstName,
			lastName,
			phoneNumber
		})

		await context.fetchUser();
		setLoading(false);
	}

	return (
		<>
			<Meta title="Settings" />

			<Header heading={"Settings"} />
			<main>

				<section>
					<form className={styles.form} onSubmit={updateUser}>
						<h2>Account Details</h2>
						<div>
							<p>Email Address:</p>
							<input type="text" value={context.user.email} disabled={true} />
						</div>

						<div>
							<p>First Name:</p>
							<input type="text" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} disabled={loading} />
						</div>

						<div>
							<p>Last Name:</p>
							<input type="text" value={lastName} onChange={(e) => { setLastName(e.target.value) }} disabled={loading} />
						</div>

						<div>
							<p>Phone Number:</p>
							<input type="text" value={phoneNumber} onChange={(e) => { setPhoneNumber(e.target.value) }} disabled={loading} />
						</div>

						<div>
							<button className="btn btnPrimary" disabled={loading}>
								{loading ?
									<Loading button={true} />
									:
									`Update Details`
								}
							</button>
						</div>
					</form>
				</section>

				{context.domain.membershipMode ? null : <section>
						<div className={styles.minors}>
							<h2>Minors (Aged 11 - 16)</h2>
							<p>You can now bring your 11 to 16 year olds to the {context.domain.name}! Click the button below to add their details and complete the online waiver form.</p>

							{minors.map((minor) => {
								return (
									<div className={styles.minor}>
										<p><img src={require("../../../images/tick.svg").default} alt="Tick" /> {minor.name}</p>
										<p>Signed: {moment(minor.signed).format("Do MMM YYYY")}</p>
									</div>
								)
							})}

							<Link to="/dashboard/settings/add-minor">
								<a className="btn btnEmpty">+ Add Minor</a>
							</Link>
						</div>
					</section>
				}

				<IssueReport />

				<Marketing />

				<DeleteAccount />

				<Link to="/log-out" className={styles.logout}>
					<a className="btn btnEmpty">Log Out</a>
				</Link>
			</main>
		</>
	);
}

export default Account;

import { Link } from 'react-router-dom';

import moment from 'moment';

import styles from '../../../../styles/Admin.module.scss';

function Item({ _id, User, session, access }) {
	return (
		<Link to={_id} className={styles.user}>
			<p>{moment(session.start).format("ddd Do MMM YYYY")} at {moment(session.start).format("HH:mm")}</p>
			<p>{User.firstName} {User.lastName} ({User.email})</p>
			<p>{session.Studio.name}</p>
		</Link>
	)
}

export default Item;
import { useSearchParams } from "react-router-dom";
import { Link } from 'react-router-dom';

import Meta from "../../components/Meta";

import styles from '../../../styles/Entry.module.scss';

function Induction() {

	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<>
			<Meta title="Video Induction" />

			<div className={styles.fullHeight}>
				<div>
					<h1>Induction</h1>
					<p>Please watch the video carefully before continuing.</p>

					<iframe className={styles.youtube} src="https://www.youtube.com/embed/YurQPfmAGfg"
							title="YouTube video player" frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen />
				</div>

				<div className={styles.actions}>
					{searchParams.get("request") === "true" ?
						<p className={styles.requested}>We have received your request for a face-to-face induction and will be in touch soon.</p>
					: null}
					<Link to="/entry/terms" className="btn btnPrimary">Continue</Link>
				</div>
			</div>
		</>
	);
}

export default Induction;

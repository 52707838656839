import { useState } from "react";

import CreateCouponAction from "../actions/CreateCoupon.action";

import styles from '../../../../styles/Admin.module.scss';

function CreateCouponForm(){
	const [coupon, setCoupon] = useState({
		name: "",
		code: "",
		percent_off: 0,
	});

	const updateField = (e) => {
		setCoupon({
			...coupon,
			[e.target.name]: e.target.value
		});
	}

	return (
		<div className={styles.form}>
			<div>
				<p>Name:</p>
				<input type="string" name="name" value={coupon.name} onChange={updateField} />
			</div>

			<div>
				<p>Code:</p>
				<input type="string" name="code" value={coupon.code} onChange={updateField} />
			</div>
			
			<div>
				<p>Percentage Off:</p>
				<input type="number" name="percent_off" min={0} max={99} value={coupon.percent_off} onChange={updateField} />
			</div>

			<CreateCouponAction {...coupon} />
		</div>
	)
}

export default CreateCouponForm;
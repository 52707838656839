import { useState, useEffect } from 'react';

import { addDays } from 'date-fns';

import moment from 'moment';
import Select from 'react-select';

import Data from '../../../repository';

import Loading from "../../components/Loading";

import CreateBookingAction from "./actions/CreateBooking.action";

import styles from '../../../styles/Admin.module.scss';


function Create() {
	const [loading, setLoading] = useState(true);

	const [users, setUsers] = useState([]);
	const [studios, setStudios] = useState([]);
	const [sessions, setSessions] = useState([]);
	const [loadingSessions, setLoadingSessions] = useState(false);

	const [user, setUser] = useState(null);
	const [studio, setStudio] = useState('');
	const [session, setSession] = useState([]);

	useEffect(async () => {
		const users = await Data.fetchUsers();
		setUsers(users.data);

		const studios = await Data.fetchStudios();
		setStudios(studios.data);

		setLoading(false);
	}, []);

	const updateStudio = async (e) => {
		setLoadingSessions(true);
		setStudio(e.target.value);
		const studio = await Data.fetchSessions(e.target.value, new Date(), addDays(new Date(), 40));
		setSessions(studio.data);
		setLoadingSessions(false);
	}

	if(loading) return <Loading button={false} />

	let fullSession = sessions.filter((obj) => {
		return obj.start === session;
	})[0];

	return (
		<div className={styles.form}>
			<div>
				<p>User</p>
				<Select className={styles.select} value={user} options={users.map((user) => {
					return {
						value: user._id,
						label: `${user.firstName} ${user.lastName} (${user.email})`
					}
				})} onChange={setUser} defaultValue={user} />
			</div>

			<div>
				<p>Studio</p>
				<select value={studio} onChange={updateStudio}>
					<option value="">Select Studio</option>
					{studios.map((studio) => {
						return <option value={studio._id}>{studio.name}</option>
					})}
				</select>
			</div>

			<div>
				<p>Session</p>
				<select value={session} onChange={(e) => {
					setSession(e.target.value)
				}}>
					{loadingSessions ?
						<option value="" disabled={true}>Loading...</option>
					:
						<>
							<option value="">Select Session</option>
							{sessions.map((session) => {
								return <option disabled={session.booked || session.past || session.withheld} value={session.start}>{moment(session.start).format('ddd Do MMM YYYY - HH:mm')}</option>
							})}
						</>
					}
				</select>
			</div>

			<CreateBookingAction user={user?.value} session={fullSession} />
		</div>
	)
}

export default Create;
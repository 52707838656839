import styles from '../../styles/Tokens.module.scss';

function Product({ _id, credits, price }) {
    return (
        <div key={_id} className={styles.product}>
            <p><strong>{credits}</strong> {credits > 1 ? "Tokens" : "Token"}</p>
            <a href={`/gift-vouchers/checkout/${_id}`} className="btn btnPrimary">£{(price / 100).toFixed(2)}</a>
        </div>
    )
}

export default Product;
import { Outlet } from 'react-router-dom';
import Meta from "../../components/Meta";

import List from "./List";
import Checkout from "./Checkout";
import Header from "../../components/Header";

function Template() {
	return (
		<>
			<Meta title="Tokens" />

			<Header heading={"Tokens"} />
			<main>
				<Outlet />
			</main>
		</>
	);
}

export default {
	Template,
	List,
	Checkout
};

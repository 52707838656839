import Header from "./components/Header";
import Form from "./components/Form";
import InputWrapper from "../components/InputWrapper";
import {NavLink} from "react-router-dom";
import {useController, useForm} from "react-hook-form";
import {useContext, useEffect, useState} from "react";
import AppContext from "../../../context";
import Data from "../Data";
import SiteSettingsImageUpload from "./components/SiteSettingsImageUpload";
import {ChromePicker, SketchPicker} from "react-color";
import Meta from "../../components/Meta";
import SiteSettingsLogoUpload from "./components/SiteSettingsLogoUpload";
import SiteSettingsFaviconUpload from "./components/SiteSettingsFaviconUpload";

const SiteSettings = () => {
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm()

    const colorField = useController({
        name: "primaryColor",
        control
    })

    useEffect(() => {
        reset(context.domain)
    }, [ context.domain])

    const submit = async (data) => {
        try {
            await Data.updateSiteSettings(data)
            context.fetchDomain()
            setError(false)
            setSuccess(true)
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Meta title={"Site Settings"} />
            <Header heading={"Site Settings"} />
            <div className={"p-6 grid grid-cols-1 gap-6"}>

                <Form heading={"Settings"} onSubmit={handleSubmit(submit)}>
                    <InputWrapper label={"Business Name"} input={<input type={"text"} {...register("name", { required: true })} />}  error={errors.name ? "This is a required field" : null}/>
                    <InputWrapper label={"Heading"} input={<input type={"text"} {...register("heading", { required: true })} />}  error={errors.heading ? "This is a required field" : null}/>
                    <InputWrapper label={"Sub Heading"} input={<input type={"text"} {...register("subHeading", { required: true })} />} error={errors.subHeading ? "This is a required field" : null}/>
                    <InputWrapper label={"Primary Color"} input={
                        <ChromePicker color={colorField.field.value} onChange={(color, event) => colorField.field.onChange(color.hex)} />
                    } error={errors.primaryColor ? "This is a required field" : null}/>
                    <div className={"mt-2 flex gap-2"}>
                        <button type={"submit"} disabled={loading} className={"bg-backgroundGrey border px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>{success ? 'Saved' : loading ? "Saving..." : "Save Changes"}</button>
                    </div>
                </Form>

                <SiteSettingsImageUpload />

                <SiteSettingsLogoUpload />

                <SiteSettingsFaviconUpload />

            </div>
        </>
    )
}

export default SiteSettings
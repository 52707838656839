import {Link, useParams} from "react-router-dom";
import GoBack from "./components/GoBack";
import Accordion from "./components/Accordion";
import {useContext, useEffect, useState} from "react";
import Data from "./Data";
import Empty from "./components/Empty";
import Meta from "../components/Meta";
import AppContext from "../../context";

const Experience = () => {
    const context = useContext(AppContext);

    const { id } = useParams();
    if(!id) window.location.href = "/"

    const [experience, setExperience] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        try {
            const experience = await Data.fetchExperienceById( { id})
            setExperience(experience.data)
        } catch (error) {
            window.location.href = "/"
        } finally {
            setLoading(false)
        }
    }, []);

    return (
        <>
            <Meta title={experience.name} />
            <div>
                <GoBack to={"/"} label={"View all experiences"} />

                <div className={"flex flex-col xl:flex-row xl:container xl:mx-auto xl:px-8"}>

                    {loading ?
                        <div className={"w-full m-8"}>
                            <Empty>
                                <p>Loading...</p>
                            </Empty>
                        </div>
                        :
                        <>
                            <div
                                className={"bg-backgroundGrey w-full aspect-video sm:h-80 sm:aspect-auto flex items-center relative lg:container lg:mx-auto lg:m-8 lg:mb-8 lg:rounded-lg object-cover xl:aspect-video overflow-hidden"}>
                                {experience.isPublished === false ? <p className={"absolute top-2 left-2 bg-red-500 text-white px-2 py-1 rounded text-sm"}>Unpublished</p> : null}
                                {experience.image ?
                                    <img src={experience.image} alt={experience.name} className={"w-full h-full object-cover"}/>
                                    : null}
                            </div>

                            <div className={"p-8 container mx-auto"}>
                                <h1 className={"text-2xl text-primary"}>{experience.name}</h1>
                                <div className={"[&_*]:text-textGrey flex gap-2 mt-1"}>
                                    <p>{experience.duration} minutes</p>
                                    <p>•</p>
                                    <p>&pound;{experience.price} per person</p>
                                </div>

                                <p className={"mt-4 text-textGrey"}>{experience.shortDescription}</p>

                                {experience.description ? <Accordion heading={"Description"} defaultOpen={true}>
                                    <p className={"text-textGrey"}>{experience.description}</p>
                                </Accordion> : null}

                                {experience.location ? <Accordion heading={"Location"} defaultOpen={true}>
                                    <p className={"text-textGrey"}>{experience.location}</p>
                                </Accordion> : null}

                                {experience.additionalDetails ? <Accordion heading={"Additional Details"}>
                                    <p className={"text-textGrey"}>{experience.additionalDetails}</p>
                                </Accordion> : null}

                                {experience.cancellationPolicy ? <Accordion heading={"Cancellation Policy"}>
                                    <p className={"text-textGrey"}>{experience.cancellationPolicy}</p>
                                </Accordion> : null}

                                <Link to={'checkout'}
                                      className={"inline-block bg-primary py-2 px-4 w-full text-white text-center rounded-md mt-6 border-0"}>Book
                                    Now</Link>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default Experience
import { useState } from "react";

import CreateStudioAction from "../actions/CreateStudio.action";

import styles from '../../../../styles/Admin.module.scss';

function CreateStudioForm(){
	const [studio, setStudio] = useState({
		name: "",
		lock: ""
	});

	const updateField = (e) => {
		setStudio({
			...studio,
			[e.target.name]: e.target.value
		});
	}

	return (
		<div className={styles.form}>
			<div>
				<p>Name:</p>
				<input type="text" name="name" value={studio.name} onChange={updateField} />
			</div>

			<div>
				<p>Lock:</p>
				<input type="text" name="lock" value={studio.lock} onChange={updateField} />
			</div>

			<CreateStudioAction {...studio} />
		</div>
	)
}

export default CreateStudioForm;
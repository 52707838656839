import Header from "../components/Header";
import {NavLink} from "react-router-dom";
import {Download, Plus} from "react-feather";
import Empty from "../components/Empty";
import {useEffect, useState} from "react";
import Data from "../../Data";
import moment from "moment/moment";
import Meta from "../../../components/Meta";

const UpcomingEvents = () => {

    const [events, setEvents] = useState([]);

    useEffect(async () => {
        const events = await Data.fetchAllEvents()
        setEvents(events.data)
    }, []);

    return (
        <>
            <Meta title={"Upcoming Events"} />
            <Header heading={"Upcoming Events"} />
            <div className={"p-6 grid grid-cols-1 gap-6 items-center"}>
                {events.length === 0 ?
                    <Empty>
                        <p>No Upcoming Events</p>
                    </Empty>
                :

                <table className={"w-full border-collapse outline outline-1 outline-gray-200 rounded-lg overflow-hidden drop-shadow-sm"}>
                    <thead className={"bg-gray-50"}>
                    <tr>
                        <th className={"text-left px-6 py-4"}>Date &amp; Time</th>
                        <th className={"text-left px-6 py-4"}>Experience</th>
                        <th className={"text-left px-6 py-4"}>Tickets</th>
                        <th className={"text-left px-6 py-4"}>Status</th>
                        <th className={"text-left px-6 py-4"}>Actions</th>
                    </tr>
                    </thead>
                    <tbody className={"bg-white"}>
                    {events.map((event, index) => (
                        <tr className={"border-t"}>
                            <td className={"px-6 py-4"}>{moment(event.date).format('MMM Do YYYY, H:mm A')}</td>
                            <td className={"px-6 py-4"}><NavLink to={`/admin/experiences/${event.Experience._id}`} className={"underline"}>{event.Experience.name}</NavLink></td>
                            <td className={"px-6 py-4"}>{event.totalTickets === 0 ? '-' : `${event.totalTickets} ticket${event.totalTickets > 1 ? 's' : ''} (${event.bookings.length} booking${event.bookings.length > 1 ? 's' : ''})`}</td>
                            <td className={"px-6 py-4"}>{event.ticketsRemaining > 0 ? `${event.ticketsRemaining} tickets remaining` : 'Sold Out'}</td>
                            <td className={"px-6 py-2"}>
                                <div className={"flex gap-2"}>
                                    <NavLink to={`/admin/upcoming-events/${event._id}`}
                                             className={"bg-backgroundGrey px-2.5 py-1 rounded hover:opacity-80 transition"}>View</NavLink>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table> }
                {/*<div className={"flex justify-center"}>*/}
                {/*    <NavLink to={""} className={"bg-gray-200 px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>Load More</NavLink>*/}
                {/*</div>*/}
            </div>
        </>
    )
}

export default UpcomingEvents
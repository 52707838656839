import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';

import moment from 'moment';

import Loading from "../../components/Loading";

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';

function Existing(){
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [minor, setMinor] = useState(null);

	useEffect(async () => {
		setLoading(true);
		const minor = await Data.fetchMinorById(id);
		setMinor(minor.data);
		setLoading(false);
	}, [])

	if(loading) return <Loading button={false} />

	return (
		<>
			<div className={styles.form}>
				<div>
					<p>Full Name:</p>
					<input type="text" value={minor.name} disabled={true} />
				</div>

				<div>
					<p>Date of Birth:</p>
					<input type="text" value={moment(minor.dateOfBirth).format("Do MMM YYYY")} disabled={true} />
				</div>

				<div>
					<p>Designated Adult:</p>
					<input type="text" value={`${minor.User.firstName} ${minor.User.lastName}`} disabled={true} />
				</div>

				<div>
					<p>Relationship:</p>
					<input type="text" value={minor.relationship} disabled={true} />
				</div>

				<div>
					<p>Alternate Contact:</p>
					<input type="text" value={minor.alternateContact} disabled={true} />
				</div>

				<div>
					<p>Signed:</p>
					<input type="text" value={moment(minor.signed).format("Do MMM YYYY")} disabled={true} />
				</div>


			</div>

			<div className={styles.actions}>
				<Link to={`/admin/users/${minor.User._id}`} className="btn btnEmpty">Go to Designated Adult</Link>
			</div>
		</>
	)
}

export default Existing;
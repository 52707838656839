import styles from '../styles/NetworkError.module.scss'

function NetworkError() {
	return (
		<div className={styles.networkError}>
			<img src={require('../images/network-error.svg').default} alt="Network Error" />
			<h1>Network Error</h1>
			<p>There was an error connecting to the internet, please check your connection and try again.</p>

			<button className={'btn btnEmpty'} onClick={() => window.location.reload()}>Try Again</button>
		</div>
	)
}

export default NetworkError;
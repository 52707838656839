import { useState, useEffect } from 'react';

import moment from 'moment';

import Loading from "../../components/Loading";
import Filters from "./components/Filters";
import Item from './components/Item';

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';
import {useSearchParams} from "react-router-dom";

function List() {
	const [searchParams, setSearchParams] = useSearchParams()
	const [loading, setLoading] = useState(true);
	const [filters, setFilters] = useState({
		user: null,
		start: moment(new Date()).format('YYYY-MM-DD'),
		end: moment(new Date()).add(7, 'days').format('YYYY-MM-DD'),
		studio: ''
	});
	const [bookings, setBookings] = useState([]);

	useEffect(async () => {
		setSearchParams({
			start: moment(new Date()).format('YYYY-MM-DD'),
			end: moment(new Date()).add(7, 'days').format('YYYY-MM-DD'),
		})
	}, [])

	useEffect(async () => {
		await refreshBookings();
	}, [searchParams]);

	const refreshBookings = async () => {
		setLoading(true);
		const bookings = await Data.fetchBookings(filters.start, filters.end);
		setBookings(bookings.data);
		setLoading(false);
	}

	if(loading) return <Loading button={false} />

	let output = bookings;
	if(filters.user && filters.user !== 'null') output = output.filter((booking) => {
		return booking.User._id === filters.user
	})
	if(filters.studio && filters.studio !== 'null') output = output.filter((booking) => {
		return booking.session.Studio._id === filters.studio
	})

	return (
		<div className={styles.withFilters}>
			<Filters bookings={bookings} filters={filters} setFilters={setFilters} />

			<div className={styles.list}>
				{!output.length && <div><p>There aren't any bookings to show.</p></div>}

				{output.map((booking) => {
					return <Item {...booking} />
				})}
			</div>
		</div>
	)
}

export default List;
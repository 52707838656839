import { Outlet } from "react-router-dom";
import { Capacitor } from '@capacitor/core';

import Sidebar from './Sidebar';
import Tabs from './Tabs';

import styles from '../../styles/Dashboard.module.scss';

import {useEffect} from "react";
import {PushNotifications} from "@capacitor/push-notifications";

import Data from '../../repository'

function Dashboard() {
	useEffect(async () => {
		if(Capacitor.isNativePlatform()){
			PushNotifications.addListener('registration',
				async (token) => {
					alert('Test')
					await Data.subscribeToNotifications(token.value)
				}
			);

			PushNotifications.requestPermissions().then(result => {
				if (result.receive === 'granted')  PushNotifications.register();
			});
		}
	}, [])

	return (
		<div className={`${styles.dashboard} ${Capacitor.isNativePlatform() ? styles.native : null}`}>
			{!Capacitor.isNativePlatform() && <Sidebar />}
			<div className={styles.outlet}>
				<Outlet  />
			</div>
			{Capacitor.isNativePlatform() && <Tabs />}
		</div>
	);
}

export default Dashboard;

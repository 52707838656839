import {useContext, useEffect, useState} from 'react';
import AppContext from "../../../context";

import Meta from "../../components/Meta";
import Header from "../../components/Header";
import Data from "../../../repository";
import Loading from "../../components/Loading";

import styles from '../../../styles/ChoosePlan.module.scss';
import moment from "moment/moment";

function ChoosePlan() {
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(true);
    const [membershipPlans, setMembershipPlans] = useState([]);
    const [memberships, setMemberships] = useState([]);

    useEffect(async () => {
        setMembershipPlans(await fetchMembershipPlans());
        setMemberships(await fetchMemberships());
        setLoading(false);
    }, [])

    const fetchMembershipPlans = async () => {
        const result = await Data.fetchMembershipPlans();
        return result.data.sort((a, b) => a.amount - b.amount);
    }

    const fetchMemberships = async () => {
        const result = await Data.fetchMemberships();
        return result.data;
    }

    const subscribe = async (membershipPlanId) => {
        setLoading(true)
        const result = await Data.subscribeToMembershipPlan(membershipPlanId);
        window.location.href = result.data.url;
    }

    if(loading) return <div className={styles.choosePlan}>
        <Loading button={false} />
    </div>

    const currentMembership = memberships[memberships.length - 1]

    return (
        <>
            {currentMembership ?
                <div className={styles.choosePlan}>
                    {currentMembership.status === "canceled" ?
                        currentMembership.cancellation_details.reason === "payment_failed" ?
                            <p>Your membership was <strong>cancelled due to an overdue payment</strong>. Select a plan below to set up a new membership.</p>
                            :
                            <p>Your membership
                                was <strong>cancelled</strong> on <strong>{moment(currentMembership.canceled_at * 1000).format('Do MMMM')}</strong>. Select a plan below to set up a new membership.
                            </p>
                        :
                        <p>Your membership is currently <strong>inactive</strong>.</p>}
                </div>
                : null}

            <div className={styles.choosePlan}>
                <h1>Membership Plans</h1>
                <p>Choose a plan that works for you.</p>

                <div className={styles.plans}>
                    {membershipPlans.map((membershipPlan) => (
                        <div className={styles.plan}>

                            <div>
                                <h2>{membershipPlan.name}</h2>
                                <div className={styles.price}>
                                    <h3>£{(membershipPlan.amount / 100).toFixed(2)}</h3>
                                    <p>/ {membershipPlan.interval_count} {membershipPlan.interval}{membershipPlan.interval_count > 1 ? 's' : ''}</p>
                                </div>
                                <p>{membershipPlan.description}</p>
                            </div>

                            <div>
                                <button onClick={() => subscribe(membershipPlan._id)}
                                        className={"btn btnPrimary"}>Choose
                                    Plan
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {context.domain.choosePlanMessage ? <div className={styles.choosePlanMessage}>
                    <p>{context.domain.choosePlanMessage}</p>
                </div> : null}
            </div>
        </>
    );
}

export default ChoosePlan;

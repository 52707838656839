import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';

import Loading from "../../components/Loading";

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';

function Existing(){
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);

	useEffect(async () => {
		await fetchUser();
	}, [])

	const fetchUser = async () => {
		setLoading(true);
		const user = await Data.fetchUserById(id);
		setUser(user.data);
		setLoading(false);
	}

	if(loading) return <Loading button={false} />

	return (
		<>
			<div className={styles.form}>
				<div>
					<p>First Name:</p>
					<input type="text" value={user.firstName} disabled={true} />
				</div>

				<div>
					<p>Last Name:</p>
					<input type="text" value={user.lastName} disabled={true} />
				</div>

				<div>
					<p>Email Address:</p>
					<input type="text" value={user.email} disabled={true} />
				</div>

				<div>
					<p>Phone Number:</p>
					<input type="text" value={user.phoneNumber} disabled={true} />
				</div>

				<div>
					<p>Verified:</p>
					<input type="text" value={user.emailVerified ? 'Yes' : 'No'} disabled={true} />
				</div>

				<div>
					<p>Admin:</p>
					<input type="text" value={user.admin ? 'Yes' : 'No'} disabled={true} />
				</div>

				<div>
					<p>Balance:</p>
					<input type="text" value={user.balance} disabled={true} />
				</div>
			</div>

			<div className={styles.actions}>
				{/*<Link to={`/admin/bookings?user=${id}`} className="btn btnEmpty">View Bookings</Link>*/}
				{/*<button className="btn btnEmpty">View Transactions</button>*/}
				{/*<button className="btn btnEmpty">Send Password Reset</button>*/}
				{/*<button className="btn btnDanger">Delete Users</button>*/}
				<Link to={`/admin/transactions/create?user=${user._id}`} className="btn btnEmpty">+ Gift Tokens</Link>
			</div>

			{user.minors.length > 0 &&
				<div className={styles.form}>
					<h3>Minors</h3>
					{user.minors.map((minor) => {
						return <Link to={`/admin/minors/${minor._id}`} className="btn btnEmpty btnLeft">{minor.name}</Link>
					})}
				</div>
			}
		</>
	)
}

export default Existing;
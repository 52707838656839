import {useContext, useEffect, useState} from "react";

import Data  from '../../repository/';
import AppContext from "../../context";

import Loading from "./Loading";

import styles from '../../styles/Account.module.scss';

function Marketing() {
	const context = useContext(AppContext);

	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState(null);

	useEffect(async () => {
		await getStatus();
		setLoading(false);
	}, []);

	const getStatus = async () => {
		try {
			const result = await Data.getMarketingStatus();
			setStatus(result.data.status);
		} catch(error){
			console.log(error)
		}
	}

	const subscribe = async () => {
		setLoading(true);
		try {
			await Data.subscribe();
			await getStatus();
			setLoading(false);
		} catch(error){
			console.log(error)
		}
	}

	const unsubscribe = async () => {
		setLoading(true);
		try {
			await Data.unsubscribe();
			await getStatus();
			setLoading(false);
		} catch(error){
			console.log(error)
		}
	}

	const resubscribe = async () => {
		setLoading(true);
		try {
			await Data.resubscribe();
			await getStatus();
			setLoading(false);
		} catch(error){
			console.log(error)
		}
	}

	if(status === null) return true;

	return (
		<section>
			<div className={styles.marketing}>
				<div>
					<h2>Marketing Preferences</h2>
					{status === 'not-found' ?
						<p>Subscribe to receive news and updates about {context.domain.name}.</p>
						: status === 'subscribed' ?
							<p>You're currently subscribed to receive news and updates about {context.domain.name}.</p>
							: status === 'unsubscribed' ?
								<p>You have unsubscribed from receiving news and updates about {context.domain.name}.</p>
								:
								null
					}
				</div>

				{status === 'not-found' ?
					<button className="btn btnPrimary" onClick={subscribe} disabled={loading}>
						{loading ?
							<Loading button={true} />
							:
							"Subscribe"
						}
					</button>
					: status === 'subscribed' ?
						<button className="btn btnEmpty" onClick={unsubscribe} disabled={loading}>
							{loading ?
								<Loading button={true} />
								:
								"Unsubscribe"
							}
						</button>
						: status === 'unsubscribed' ?
							<button className="btn btnPrimary" onClick={resubscribe} disabled={loading}>
								{loading ?
									<Loading button={true} />
									:
									"Re-subscribe"
								}
							</button>
							:
							null
				}
			</div>
		</section>
	)
}

export default Marketing;
import {useEffect, useRef, useState, memo} from 'react';

import Day from "./Day";
import Loading from "../../../components/Loading";

import Data from '../../../../repository';

import styles from '../../../../styles/Calendar.module.scss';

function Sessions({ studio, start, end, loading, setLoading }) {
	const [sessions, setSessions] = useState([]);

	const prevDeps = useRef({ studio: null, start: null, end: null });

	useEffect(async () => {
		const hasDepsChanged =
			prevDeps.current.studio !== studio ||
			prevDeps.current.start !== start ||
			prevDeps.current.end !== end;

		if(hasDepsChanged) {
			await setSessions(await fetchSessions(studio, start, end));
			await setLoading(false);
		}
	}, [studio, start, end]);

	const fetchSessions = async (studio, start, end) => {
		const result = await Data.fetchSessions(studio, start, end);
		return result.data;
	}

	const days = sessions.reduce((acc, session) => {
		if(!acc.includes(session.start.split("T")[0])) acc.push(session.start.split("T")[0]);
		return acc;
	}, []);

	if(loading) return <Loading button={false} />

	return (
		<div className={styles.sessions}>
			{days.map(day => <Day day={day} sessions={sessions} studio={studio} />)}
		</div>
	)
}

export default memo(Sessions);
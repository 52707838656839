import { useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';

import Loading from "../../components/Loading";

import Data from '../../../repository';

function Join() {
	const { shareLink } = useParams();
	const navigate = useNavigate();

	useEffect(async () => {
		try {
			if(!shareLink) navigate('/dashboard/book');
			let booking = await Data.fetchStudioByShareLink(shareLink);
			booking = booking.data;
			if(!booking) navigate('/dashboard/book');
			navigate(`/dashboard/book/${booking.studio}/confirm/?start=${booking.start}&end=${booking.end}&shareLink=${shareLink}`)
		} catch(error) {
			navigate('/dashboard/book');
		}
	}, []);

	return <Loading button={false} />;
}

export default Join;
import Form from "../../components/Form";
import InputWrapper from "../../../components/InputWrapper";
import {useFieldArray, useForm} from "react-hook-form";
import TimesField from "./TimesField";
import {Plus} from "react-feather";
import { addDays, setHours, setMinutes, setSeconds } from 'date-fns';
import moment from "moment/moment";
import {useParams} from "react-router-dom";
import {useState} from "react";
import Data from "../../../Data";
import Error from "../../components/Error";


const Weekly = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [eventsCreated, setEventsCreated] = useState(0);

    const { register, handleSubmit, formState: { errors }, control } = useForm();

    // Create field arrays for each day of the week
    const monTimes = useFieldArray({ name: 'monTimes', control });
    const tueTimes = useFieldArray({ name: 'tueTimes', control });
    const wedTimes = useFieldArray({ name: 'wedTimes', control });
    const thuTimes = useFieldArray({ name: 'thuTimes', control });
    const friTimes = useFieldArray({ name: 'friTimes', control });
    const satTimes = useFieldArray({ name: 'satTimes', control });
    const sunTimes = useFieldArray({ name: 'sunTimes', control });

    const submit = async (data) => {
        const { startDate, endDate, monTimes, tueTimes, wedTimes, thuTimes, friTimes, satTimes, sunTimes } = data;

        const start = setSeconds(setMinutes(setHours(new Date(startDate), 0), 0), 0);  // Set time to 00:00:00
        const end = setSeconds(setMinutes(setHours(new Date(endDate), 23), 59), 59);  // Set time to 23:59:59

        let currentDate = start;
        const weeklyEvents = [];

        while (currentDate <= end) {
            const dayOfWeek = currentDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

            // Helper function to create Date objects from startTime
            const createEventDate = (baseDate, startTime) => {
                const [hours, minutes] = startTime.split(':').map(Number);
                return setMinutes(setHours(new Date(baseDate), hours), minutes);
            };

            // Monday
            if (dayOfWeek === 1 && monTimes) {
                monTimes.forEach((startTime) => {
                    const eventDate = createEventDate(currentDate, startTime);
                    weeklyEvents.push(eventDate);
                });
            }
            // Tuesday
            if (dayOfWeek === 2 && tueTimes) {
                tueTimes.forEach((startTime) => {
                    const eventDate = createEventDate(currentDate, startTime);
                    weeklyEvents.push(eventDate);
                });
            }
            // Wednesday
            if (dayOfWeek === 3 && wedTimes) {
                wedTimes.forEach((startTime) => {
                    const eventDate = createEventDate(currentDate, startTime);
                    weeklyEvents.push(eventDate);
                });
            }
            // Thursday
            if (dayOfWeek === 4 && thuTimes) {
                thuTimes.forEach((startTime) => {
                    const eventDate = createEventDate(currentDate, startTime);
                    weeklyEvents.push(eventDate);
                });
            }
            // Friday
            if (dayOfWeek === 5 && friTimes) {
                friTimes.forEach((startTime) => {
                    const eventDate = createEventDate(currentDate, startTime);
                    weeklyEvents.push(eventDate);
                });
            }
            // Saturday
            if (dayOfWeek === 6 && satTimes) {
                satTimes.forEach((startTime) => {
                    const eventDate = createEventDate(currentDate, startTime);
                    weeklyEvents.push(eventDate);
                });
            }
            // Sunday
            if (dayOfWeek === 0 && sunTimes) {
                sunTimes.forEach((startTime) => {
                    const eventDate = createEventDate(currentDate, startTime);
                    weeklyEvents.push(eventDate);
                });
            }

            // Move to the next day
            currentDate = addDays(currentDate, 1);
        }

        if(window.confirm(`Your are about to create the following events:\n${weeklyEvents.map(date => `\n${moment(date).format('ddd Do MMM YY HH:mm A')}`)} \n\nAre you sure?`)){
            try {
                setLoading(true)
                await Data.createEvents({ experienceId: id, dates: weeklyEvents })
                setEventsCreated(weeklyEvents.length)
                setError(false)
            } catch(error){
                setError(true)
            } finally {
                setLoading(false)
            }
        }
    };

    return (
        <Form heading={"Weekly"} onSubmit={handleSubmit(submit)}>
            <p className={"text-textGrey"}>Add events Mon-Sun between two dates.</p>
            <div className="grid grid-cols-2 gap-4">
                <InputWrapper label={'Start Date'} input={<input type={'date'} {...register('startDate', { required: true })} />} error={errors.startDate ? 'This field is required' : null} />
                <InputWrapper label={'End Date'} input={<input type={'date'} {...register('endDate', { required: true })} />} error={errors.endDate ? 'This field is required' : null} />

                {/* Monday Times */}
                <InputWrapper label={`Monday's Times`} input={
                    <div className={"p-2 min-h-20 border rounded-md flex flex-wrap gap-4 items-center"}>
                        {monTimes.fields.map((field, index) => (
                            <TimesField register={register} remove={monTimes.remove} name={'monTimes'} index={index} />
                        ))}
                        <button type={"button"} onClick={() => monTimes.append({})}
                                className={"rounded-full p-0 w-8 h-8 flex items-center justify-center border-0 bg-gray-500"}><Plus color={"#ffffff"} /></button>
                    </div>
                } />

                {/* Tuesday Times */}
                <InputWrapper label={`Tuesday's Times`} input={
                    <div className={"p-2 min-h-20 border rounded-md flex flex-wrap gap-4 items-center"}>
                        {tueTimes.fields.map((field, index) => (
                            <TimesField register={register} remove={tueTimes.remove} name={'tueTimes'} index={index} />
                        ))}
                        <button type={"button"} onClick={() => tueTimes.append({})}
                                className={"rounded-full p-0 w-8 h-8 flex items-center justify-center border-0 bg-gray-500"}><Plus color={"#ffffff"} /></button>
                    </div>
                } />

                {/* Wednesday Times */}
                <InputWrapper label={`Wednesday's Times`} input={
                    <div className={"p-2 min-h-20 border rounded-md flex flex-wrap gap-4 items-center"}>
                        {wedTimes.fields.map((field, index) => (
                            <TimesField register={register} remove={wedTimes.remove} name={'wedTimes'} index={index} />
                        ))}
                        <button type={"button"} onClick={() => wedTimes.append({})}
                                className={"rounded-full p-0 w-8 h-8 flex items-center justify-center border-0 bg-gray-500"}><Plus color={"#ffffff"} /></button>
                    </div>
                } />

                {/* Thursday Times */}
                <InputWrapper label={`Thursday's Times`} input={
                    <div className={"p-2 min-h-20 border rounded-md flex flex-wrap gap-4 items-center"}>
                        {thuTimes.fields.map((field, index) => (
                            <TimesField register={register} remove={thuTimes.remove} name={'thuTimes'} index={index} />
                        ))}
                        <button type={"button"} onClick={() => thuTimes.append({})}
                                className={"rounded-full p-0 w-8 h-8 flex items-center justify-center border-0 bg-gray-500"}><Plus color={"#ffffff"} /></button>
                    </div>
                } />

                {/* Friday Times */}
                <InputWrapper label={`Friday's Times`} input={
                    <div className={"p-2 min-h-20 border rounded-md flex flex-wrap gap-4 items-center"}>
                        {friTimes.fields.map((field, index) => (
                            <TimesField register={register} remove={friTimes.remove} name={'friTimes'} index={index} />
                        ))}
                        <button type={"button"} onClick={() => friTimes.append({})}
                                className={"rounded-full p-0 w-8 h-8 flex items-center justify-center border-0 bg-gray-500"}><Plus color={"#ffffff"} /></button>
                    </div>
                } />

                {/* Saturday Times */}
                <InputWrapper label={`Saturday's Times`} input={
                    <div className={"p-2 min-h-20 border rounded-md flex flex-wrap gap-4 items-center"}>
                        {satTimes.fields.map((field, index) => (
                            <TimesField register={register} remove={satTimes.remove} name={'satTimes'} index={index} />
                        ))}
                        <button type={"button"} onClick={() => satTimes.append({})}
                                className={"rounded-full p-0 w-8 h-8 flex items-center justify-center border-0 bg-gray-500"}><Plus color={"#ffffff"} /></button>
                    </div>
                } />

                {/* Sunday Times */}
                <InputWrapper label={`Sunday's Times`} input={
                    <div className={"p-2 min-h-20 border rounded-md flex flex-wrap gap-4 items-center"}>
                        {sunTimes.fields.map((field, index) => (
                            <TimesField register={register} remove={sunTimes.remove} name={'sunTimes'} index={index} />
                        ))}
                        <button type={"button"} onClick={() => sunTimes.append({})}
                                className={"rounded-full p-0 w-8 h-8 flex items-center justify-center border-0 bg-gray-500"}><Plus color={"#ffffff"} /></button>
                    </div>
                } />
            </div>
            <div className={"flex items-center gap-4"}>
                <button disabled={loading}
                        className={"bg-backgroundGrey border px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>{loading ? 'Creating Events...' : 'Create'}
                </button>
                {eventsCreated ? <p className={"text-green-600"}>{eventsCreated} Event{eventsCreated > 1 ? 's' : ''} Created</p> : null}
            </div>

            {error ? <Error message={"An error occurred, please try again"} /> : null }
        </Form>
    );
};

export default Weekly;
import Form from "../../components/Form";
import InputWrapper from "../../../components/InputWrapper";
import {useState} from "react";
import {useFieldArray, useForm} from "react-hook-form";
import {Plus, X} from "react-feather";
import moment from "moment";
import TimesField from "./TimesField";
import {ca} from "date-fns/locale";
import Data from "../../../Data";
import {useParams} from "react-router-dom";
import Error from "../../components/Error";

const Range = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [eventsCreated, setEventsCreated] = useState(0);

    const { register, handleSubmit, formState: { errors }, control } = useForm()
    const times = useFieldArray({
        name: 'times',
        control
    })

    const submit = async ({ startDate, endDate, times }) => {
        setEventsCreated(0)

        const result = [];

        // Convert string dates to Date objects
        let currentDate = new Date(startDate);
        const end = new Date(endDate);

        while (currentDate <= end) {
            // For each time in the times array, create a new Date object
            times.forEach(time => {
                const [hours, minutes] = time.split(':');
                const newDate = new Date(currentDate);

                // Set hours and minutes to the new date
                newDate.setHours(parseInt(hours, 10));
                newDate.setMinutes(parseInt(minutes, 10));

                // Push the new date-time object to the result array
                result.push(newDate);
            });

            // Move to the next day
            currentDate.setDate(currentDate.getDate() + 1);
        }

        if(window.confirm(`Your are about to create the following events:\n${result.map(date => `\n${moment(date).format('ddd Do MMM YY HH:mm A')}`)} \n\nAre you sure?`)){
            try {
                setLoading(true)
                await Data.createEvents({ experienceId: id, dates: result })
                setEventsCreated(result.length)
                setError(false)
            } catch(error){
                setError(true)
            } finally {
                setLoading(false)
            }
        }
    }

    return (
        <Form heading={"Range"} onSubmit={handleSubmit(submit)}>
            <p className={"text-textGrey"}>Add a one or more events for every day between two dates.</p>
            <div className="grid grid-cols-2 gap-4">
                <InputWrapper label={'Start Date'} input={<input type={'date'} {...register('startDate', { required: true })} />} error={errors.startDate ? 'This field is required' : null} />
                <InputWrapper label={'End Date'} input={<input type={'date'} {...register('endDate', { required: true })} />} error={errors.endDate ? 'This field is required' : null} />
            </div>
            <InputWrapper label={'Daily Times'} input={
                <div className={"p-2 min-h-20 border rounded-md flex flex-wrap gap-4 items-center"}>
                    {times.fields.map((field, index) => (
                        <TimesField register={register} remove={times.remove} name={'times'} index={index} />
                    ))}
                    <button type={"button"} onClick={times.append}
                            className={"rounded-full p-0 w-8 h-8 flex items-center justify-center border-0 bg-gray-500"}><Plus color={"#ffffff"} /></button>
                </div>
            } />
            <div className={"flex items-center gap-4"}>
                <button disabled={loading}
                    className={"bg-backgroundGrey border px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>{loading ? 'Creating Events...' : 'Create'}
                </button>
                {eventsCreated ? <p className={"text-green-600"}>{eventsCreated} Event{eventsCreated > 1 ? 's' : ''} Created</p> : null}
            </div>

            {error ? <Error message={"An error occurred, please try again"} /> : null }
        </Form>
    )
}

export default Range
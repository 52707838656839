import Header from "../components/Header";
import {EyeOff, Plus} from "react-feather";
import {NavLink} from "react-router-dom";
import Empty from "../components/Empty";
import {useEffect, useState} from "react";
import Data from "../../Data";
import Error from "../components/Error";
import Meta from "../../../components/Meta";

const Experiences = () => {

    const [experiences, setExperiences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(async () => {
        try {
            const experiences = await Data.fetchExperiences()
            setExperiences(experiences.data)
        } catch(error){
            setError(true)
        } finally {
            setLoading(false)
        }
    }, []);

    return (
        <>
            <Meta title={"Experiences"} />
            <Header heading={"Experiences"} buttonLabel={"Create Experience"} buttonIcon={<Plus size={20} /> } buttonTo={"/admin/experiences/create"} />
            <div className={"p-6 grid grid-cols-1 gap-6"}>

                {error ? <Error message={"Unable to load experiences, please refresh the page."} /> : null}

                {loading ?
                    <Empty>
                        <p>Loading...</p>
                    </Empty>
                    : experiences.length === 0 ?
                        <Empty>
                            <p>No Experiences</p>
                        </Empty>
                    :

                        <table
                            className={"w-full border-collapse outline outline-1 outline-gray-200 rounded-lg overflow-hidden drop-shadow-sm"}>
                            <thead className={"bg-gray-50"}>
                            <tr>
                                <th className={"text-left px-6 py-4"}>Name</th>
                                <th className={"text-left px-6 py-4"}>Status</th>
                                <th className={"text-left px-6 py-4"}>Upcoming Events</th>
                                <th className={"text-left px-6 py-4"}>Duration</th>
                                <th className={"text-left px-6 py-4"}>Price</th>
                                <th className={"text-left px-6 py-4"}>Actions</th>
                            </tr>
                            </thead>
                            <tbody className={"bg-white"}>
                            {experiences.map((experience, index) => (
                                <tr className={"border-t"} key={index}>
                                    <td className={"px-6 py-4"}>{experience.name}</td>
                                    <td className={"px-6 py-4"}>{experience.isPublished ? "Published" : "Unpublished"}</td>
                                    <td className={"px-6 py-4"}>{experience.futureEvents.length > 0 ? experience.futureEvents.length : '--'}</td>
                                    <td className={"px-6 py-4"}>{experience.duration} minutes</td>
                                    <td className={"px-6 py-4"}>&pound;{experience.price} per ticket</td>
                                    <td className={"px-6 py-2"}>
                                        <div className={"flex gap-2"}>
                                            <NavLink to={`/admin/experiences/${experience._id}`}
                                                     className={"bg-backgroundGrey px-2.5 py-1 rounded hover:opacity-80 transition"}>View</NavLink>
                                        </div>
                                    </td>
                                </tr>
                            ))}

                            </tbody>
                        </table>
                }
            </div>
        </>
    )
}

export default Experiences
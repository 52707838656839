import axios from "axios";

const config = {
	headers: {
		Authorization: `Bearer ${localStorage.getItem("token")}`
	}
};

const fetchDomain = async () => {
	return axios.get(`${process.env.REACT_APP_API}/domains?domain=${process.env.REACT_APP_DOMAIN}`);
}

const fetchUser = async () => {
	return axios.get(`${process.env.REACT_APP_API}/users/me`, config);
}

const fetchUserById = async (id) => {
	return axios.get(`${process.env.REACT_APP_API}/users/${id}`, config);
}

const updateUser = async ({ firstName, lastName, phoneNumber }) => {
	return axios.put(`${process.env.REACT_APP_API}/users/me`, {
		firstName, lastName, phoneNumber
	}, config);
}

const createAccount = async ({email, password, firstName, lastName, phoneNumber, subscribe}) => {
	return axios.post(`${process.env.REACT_APP_API}/users/`, {
		Domain: process.env.REACT_APP_DOMAIN,
		email,
		password,
		firstName,
		lastName,
		phoneNumber,
		subscribe
	})
}

const login = async (email, password) => {
	return axios.post(`${process.env.REACT_APP_API}/authentication/login`, {
		Domain: process.env.REACT_APP_DOMAIN,
		email,
		password
	})
}

const verifyEmailAddress = async (pin) => {
	return axios.put(`${process.env.REACT_APP_API}/users/verify-email-address`, {
		pin
	}, config)
}

const resendEmailVerification = async () => {
	return axios.put(`${process.env.REACT_APP_API}/users/resend-email-verification`, {}, config);
}

const requestInduction = async (body) => {
	return axios.post(`${process.env.REACT_APP_API}/users/request-induction`, {
		...body,
		Domain: process.env.REACT_APP_DOMAIN
	}, config);
}

const acceptTerms = async () => {
	return axios.put(`${process.env.REACT_APP_API}/users/accept-terms`, {}, config);
}

const requestPasswordReset = async (email) => {
	return axios.put(`${process.env.REACT_APP_API}/users/request-password-reset`, {
		Domain: process.env.REACT_APP_DOMAIN,
		email
	});
}

const resetPassword = async (email, newPassword, token) => {
	return axios.put(`${process.env.REACT_APP_API}/users/change-password`, {
		Domain: process.env.REACT_APP_DOMAIN,
		email,
		newPassword,
		token
	})
}

const fetchStudios = async () => {
	return axios.get(`${process.env.REACT_APP_API}/studios?location=${process.env.REACT_APP_LOCATION}`, config);
}

const fetchStudio = async (id) => {
	return axios.get(`${process.env.REACT_APP_API}/studios/${id}`, config);
}

const fetchStudioByShareLink = async (shareLink) => {
	return axios.get(`${process.env.REACT_APP_API}/bookings/join/${shareLink}`, config);
}

const updateStudio = async (studio) => {
	return axios.put(`${process.env.REACT_APP_API}/studios/${studio._id}`, {
		...studio
	}, config);
}

const createStudio = async (studio) => {
	return axios.post(`${process.env.REACT_APP_API}/studios`, {
		...studio,
		Location: process.env.REACT_APP_LOCATION
	}, config);
}

const fetchSessions = async (studio, start, end) => {
	return axios.get(`${process.env.REACT_APP_API}/studios/${studio}/sessions?start=${start}&end=${end}`, config);
}

const fetchSession = async (studio, start, end, shareLink) => {
	return await axios.get(`${process.env.REACT_APP_API}/studios/${studio}/session?start=${start}&end=${end}&shareLink=${shareLink}`, config);
}

const createBooking = async (session, shareLink) => {
	return axios.post(`${process.env.REACT_APP_API}/bookings`, { session, shareLink }, config);
}

const createUserBooking = async (booking) => {
	return axios.post(`${process.env.REACT_APP_API}/bookings`, { ...booking }, config);
}

const fetchBookings = async (start, end) => {
	return axios.get(`${process.env.REACT_APP_API}/bookings?start=${start}&end=${end}`, config);
}

const fetchBooking = async (id) => {
	return axios.get(`${process.env.REACT_APP_API}/bookings/${id}`, config);
}

const cancelBooking = async (id) => {
	return axios.put(`${process.env.REACT_APP_API}/bookings/${id}/cancel`, {}, config);
}

const fetchProducts = async () => {
	return axios.get(`${process.env.REACT_APP_API}/products/admin`, config);
}

const fetchPublicProducts = async () => {
	return axios.get(`${process.env.REACT_APP_API}/products/${process.env.REACT_APP_DOMAIN}`);
}

const fetchProduct = async (id) => {
	return axios.get(`${process.env.REACT_APP_API}/products/admin/${id}`, config);
}

const fetchPublicProduct = async (id) => {
	return axios.get(`${process.env.REACT_APP_API}/products/${process.env.REACT_APP_DOMAIN}/${id}`);
}

const updateProduct = async (product) => {
	return axios.put(`${process.env.REACT_APP_API}/products/admin/${product._id}`, {
		...product
	}, config);
}

const createProduct = async (product) => {
	return axios.post(`${process.env.REACT_APP_API}/products/admin`, {
		...product
	}, config);
}

const createPaypalOrder = async (product, discountCode) => {
	return axios.post(`${process.env.REACT_APP_API}/transactions`, {
		product,
		discountCode
	}, config);
}

const createPaypalGiftVoucherOrder = async (product, discountCode) => {
	return axios.post(`${process.env.REACT_APP_API}/transactions/gift-vouchers`, {
		Domain: process.env.REACT_APP_DOMAIN,
		product,
		discountCode
	}, config);
}

const approvePaypalOrder = async (orderId) => {
	return axios.post(`${process.env.REACT_APP_API}/transactions/${orderId}/capture`, {
		Domain: process.env.REACT_APP_DOMAIN,
	}, config);
}

const approvePaypalGiftVoucherOrder = async (orderId) => {
	return axios.post(`${process.env.REACT_APP_API}/transactions/gift-vouchers/${orderId}/capture`, {
		Domain: process.env.REACT_APP_DOMAIN,
	}, config);
}

const fetchTransactions = async () => {
	return axios.get(`${process.env.REACT_APP_API}/transactions`, config);
}

const fetchTransaction = async (id) => {
	return axios.get(`${process.env.REACT_APP_API}/transactions/${id}`, config);
}

const createTransaction = async (transaction) => {
	return axios.post(`${process.env.REACT_APP_API}/transactions/adjustment`, {
		...transaction
	}, config);
}

const fetchMinors = async () => {
	return axios.get(`${process.env.REACT_APP_API}/minors`, config);
}

const fetchMinorById = async (id) => {
	return axios.get(`${process.env.REACT_APP_API}/minors/${id}`, config);
}

const createMinor = async (minor) => {
	return axios.post(`${process.env.REACT_APP_API}/minors`, minor, config);
}

const fetchUsers = async () => {
	return axios.get(`${process.env.REACT_APP_API}/users`, config);
}

const fetchDiscountCodes = async () => {
	return axios.get(`${process.env.REACT_APP_API}/discount-codes`, config);
}

const fetchDiscountCode = async (id) => {
	return axios.get(`${process.env.REACT_APP_API}/discount-codes/${id}`, config);
}

const updateDiscountCode = async (discountCode) => {
	return axios.put(`${process.env.REACT_APP_API}/discount-codes/${discountCode._id}`, {
		...discountCode
	}, config);
}

const createDiscountCode = async (discountCode) => {
	return axios.post(`${process.env.REACT_APP_API}/discount-codes`, {
		...discountCode
	}, config);
}

const checkDiscountCode = async (code) => {
	return axios.get(`${process.env.REACT_APP_API}/discount-codes/code/${code}`, config);
}

const getMarketingStatus = async () => {
	return axios.get(`${process.env.REACT_APP_API}/marketing`, config);
}

const subscribe = async () => {
	return axios.put(`${process.env.REACT_APP_API}/marketing/subscribe`, {}, config);
}

const unsubscribe = async () => {
	return axios.put(`${process.env.REACT_APP_API}/marketing/unsubscribe`, {}, config);
}

const resubscribe = async () => {
	return axios.put(`${process.env.REACT_APP_API}/marketing/resubscribe`, {}, config);
}

const sendIssueReport = async ({ category, report }) => {
	return axios.post(`${process.env.REACT_APP_API}/issue`, {
		category,
		report
	}, config);
}

const redeemGiftVoucher = async (voucherCode) => {
	return axios.post(`${process.env.REACT_APP_API}/transactions/gift-vouchers/redeem`, { voucherCode }, config)
}

const subscribeToNotifications = async token => {
	return axios.post(`${process.env.REACT_APP_API}/notifications/subscribe`, { token }, config)
}

const deleteAccount = async () => {
	return axios.delete(`${process.env.REACT_APP_API}/users/me`, config);
}

const fetchMembershipPlans = async () => {
	return axios.get(`${process.env.REACT_APP_API}/membership-plans`, config);
}

const fetchMembershipPlan = async (id) => {
	return axios.get(`${process.env.REACT_APP_API}/membership-plans/${id}`, config);
}

const createMembershipPlan = async ({name, description, amount, active, interval_count, interval, lockstateLockId, secondaryLockstateLockId, startTime, endTime}) => {
	return axios.post(`${process.env.REACT_APP_API}/membership-plans`, {
		name,
		description,
		amount,
		active,
		interval_count,
		interval,
		lockstateLockId,
		secondaryLockstateLockId,
		startTime,
		endTime
	}, config);
}

const updateMembershipPlan = async ({_id, name, description, amount, active, interval_count, interval, lockstateLockId, secondaryLockstateLockId, startTime, endTime}) => {
	return axios.put(`${process.env.REACT_APP_API}/membership-plans/${_id}`, {
		name,
		description,
		amount,
		active,
		interval_count,
		interval,
		lockstateLockId,
		secondaryLockstateLockId,
		startTime,
		endTime
	}, config);
}

const subscribeToMembershipPlan = async (membershipPlanId) => {
	return axios.post(`${process.env.REACT_APP_API}/memberships`, {
		membershipPlanId
	}, config)
}

const fetchMemberships = async () => {
	return axios.get(`${process.env.REACT_APP_API}/memberships`, config);
}

const fetchBillingPortalSession = async (flowType) => {
	return axios.post(`${process.env.REACT_APP_API}/memberships/billing-portal`, {
		flowType
	}, config);
}

const updatePersonalAccessCode = async (personalAccessCode) => {
	return axios.put(`${process.env.REACT_APP_API}/users/personal-access-code`, {
		personalAccessCode
	}, config);
}

const fetchCoupons = async () => {
	return axios.get(`${process.env.REACT_APP_API}/coupons`, config);
}

const fetchCoupon = async id => {
	return axios.get(`${process.env.REACT_APP_API}/coupons/${id}`, config);
}

const createCoupon = async ({name, percent_off, code}) => {
	return axios.post(`${process.env.REACT_APP_API}/coupons`, {
		name,
		percent_off,
		code
	}, config);
}

const deleteCoupon = async id => {
	return axios.delete(`${process.env.REACT_APP_API}/coupons/${id}`, config);
}

export default {
	fetchDomain,
	fetchUser,
	fetchUserById,
	updateUser,
	createAccount,
	login,
	verifyEmailAddress,
	resendEmailVerification,
	requestInduction,
	acceptTerms,
	requestPasswordReset,
	resetPassword,
	fetchStudios,
	fetchStudio,
	fetchStudioByShareLink,
	updateStudio,
	createStudio,
	fetchSessions,
	fetchSession,
	createBooking,
	createUserBooking,
	fetchBookings,
	fetchBooking,
	cancelBooking,
	fetchProducts,
	fetchProduct,
	createPaypalOrder,
	approvePaypalOrder,
	fetchTransactions,
	fetchTransaction,
	createTransaction,
	fetchMinors,
	fetchMinorById,
	createMinor,
	getMarketingStatus,
	subscribe,
	unsubscribe,
	resubscribe,
	sendIssueReport,
	createPaypalGiftVoucherOrder,
	approvePaypalGiftVoucherOrder,
	subscribeToNotifications,
	fetchMembershipPlans,
	fetchMembershipPlan,
	createMembershipPlan,
	updateMembershipPlan,
	subscribeToMembershipPlan,
	fetchMemberships,
	fetchBillingPortalSession,
	updatePersonalAccessCode,

	// ADMIN
	updateProduct,
	createProduct,

	fetchUsers,

	fetchDiscountCodes,
	fetchDiscountCode,
	updateDiscountCode,
	createDiscountCode,
	checkDiscountCode,
	redeemGiftVoucher,
	fetchPublicProducts,
	fetchPublicProduct,
	deleteAccount,
	fetchCoupons,
	fetchCoupon,
	createCoupon,
	deleteCoupon,
}
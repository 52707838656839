import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Loading from "../../components/Loading";

import Item from './components/Item';

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';

function List() {
	const [loading, setLoading] = useState(true);
	let [users, setUsers] = useState([]);
	const [search, setSearch] = useState("");

	useEffect(async () => {
		await refreshUsers();
	}, [])

	const refreshUsers = async () => {
		setLoading(true);
		const users = await Data.fetchUsers();
		setUsers(users.data);
		setLoading(false);
	}

	if(loading) return <Loading button={false} />

	users = users.filter((user) => {
		const query = `${user.firstName} ${user.lastName} (${user.email})`;
		return query.toLowerCase().includes(search.toLowerCase());
	})

	return (
		<div className={styles.list}>
			<input type="text" value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder="Search by name or email..." />

			{!users.length && <div><p>There aren't any users to show.</p></div>}

			{users.map((user) => {
				return <Item {...user} />
			})}
		</div>
	)
}

export default List;
import Form from "../../components/Form";
import {useForm} from "react-hook-form";
import InputWrapper from "../../../components/InputWrapper";

const One = () => {
    return (
        <Form heading={"One"}>
            <p className={"text-textGrey"}>Add a single event for a specific date and time.</p>
            <InputWrapper label={'Date and Time'} input={<input type={'datetime-local'}/>} />
            <div>
                <button
                    className={"bg-backgroundGrey border px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>Create
                </button>
            </div>
        </Form>
    )
}

export default One
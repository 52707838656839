import {X} from "react-feather";

const TimesField = ({ register, remove, name, index }) => {
    return (
        <div className={"relative group"}>
            <input type={"time"} {...register(`${name}.${index}`)} />
            <button type={"button"} onClick={() => remove(index)}
                    className={"absolute hidden group-hover:flex right-0 rounded-full p-0 w-5 h-5 items-center justify-center border-0 bg-gray-500 top-1/2 transform translate-x-1/2 -translate-y-1/2 z-10"}>
                <X color={"#ffffff"} size={16}/></button>
        </div>
    )
}

export default TimesField
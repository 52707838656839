import {useContext, useEffect, useState} from "react";

import Data  from '../../repository/';
import AppContext from "../../context";

import Loading from "./Loading";

import styles from '../../styles/Account.module.scss';

function DeleteAccount() {
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(false);

    const deleteAccount = async () => {
        setLoading(true);
        if(window.confirm("Are you sure you want to delete your account? This cannot be undone.")){
            try {
                await Data.deleteAccount();
                window.location.reload();
            } catch(error){
                console.log(error)
            }
        }
        setLoading(false);
    }

    return (
        <section>
            <div className={styles.marketing}>
                <div>
                    <h2>Delete Account</h2>
                    <p>Entirely delete your account, your bookings and your current token balance. This action cannot be undone.</p>
                </div>

                <button className="btn btnDanger" onClick={deleteAccount} disabled={loading}>
                    {loading ?
                        <Loading button={true} />
                    :
                        "Delete Account"
                    }
                </button>
            </div>
        </section>
    )
}

export default DeleteAccount;
import { Link } from 'react-router-dom';

import styles from '../../../../styles/Admin.module.scss';

function Item({ _id, enabled, name, code }) {
	return (
		<Link to={_id} className={`${styles.discountCode} ${enabled ? "" : styles.disabled}`}>
			<p>{name} ({code})</p>
		</Link>
	)
}

export default Item;
import {NavLink} from "react-router-dom";
import {ArrowLeft, ArrowRight, Plus} from "react-feather";

const Header = ({ heading, buttonLabel, buttonIcon, buttonTo, backToLabel, backToTo }) => {
    return (
        <div className={"bg-white border-b p-6 flex justify-between items-center min-h-28"}>
            <div className={"flex flex-col gap-1"}>
                {backToLabel && backToTo ? <NavLink to={backToTo} className={"flex gap-1 items-center text-textGrey"}><ArrowLeft size={18} />{backToLabel}</NavLink> : null}
                <p className={"text-xl"}>{heading}</p>
            </div>
            {buttonLabel && buttonIcon && buttonTo ? <NavLink to={buttonTo} className={"bg-primary text-white flex gap-1 items-center px-2.5 py-1.5 rounded-md hover:opacity-80 transition"}>{buttonIcon} {buttonLabel}</NavLink> : null}
        </div>
    )
}

export default Header
import { Link } from 'react-router-dom';
import moment from 'moment';

import styles from "../../../../styles/Admin.module.scss";

function Item(transaction) {
	const giftVoucher = transaction.giftVoucher?.code && transaction.giftVoucher?.code !== 'false'

	return (
		<Link to={transaction._id} className={styles.transaction}>
			<p>{moment(transaction.createdAt).fromNow()}</p>
			<p>{giftVoucher && 'Gift Voucher'} {transaction.User?.firstName} {transaction.User?.lastName}</p>
			{transaction.Product ?
				<p>{transaction.Product.credits} {`Token${transaction.Product.credits === 1 ? '' : 's'}`}</p>
			:
				<p>{transaction.adjustment} {`Token${(transaction.adjustment < 1 || transaction.adjustment > -1) ? '' : 's'}`}</p>
			}
		</Link>
	)
}

export default Item;
import { useState, useEffect } from 'react';

import Product from "./Product";
import Loading from "../components/Loading";

import Data from "../../repository";

import styles from '../../styles/Tokens.module.scss';

function GiftVouchers() {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);

    useEffect(async () => {
        setProducts(await fetchProducts());
        setLoading(false);
    }, [])

    const fetchProducts = async () => {
        const result = await Data.fetchPublicProducts();
        return result.data;
    }

    if(loading) return <Loading button={false} />

    return (
        <div className={styles.products}>
            {!products.length && <div className={styles.none}><p>There aren't any products available for purchase, please try again later.</p></div>}

            {products.map((product) => {
                return <Product {...product} />
            })}
        </div>
    );
}

export default GiftVouchers;

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Loading from "../../components/Loading";

import Item from './components/Item';

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';

function List() {
	const [loading, setLoading] = useState(true);
	const [products, setProducts] = useState([]);

	useEffect(async () => {
		await refreshProducts();
	}, [])

	const refreshProducts = async () => {
		setLoading(true);
		let products = await Data.fetchProducts();
		products = products.data.sort((a, b) => {
			return b.enabled - a.enabled
		})
		setProducts(products);
		setLoading(false);
	}

	if(loading) return <Loading button={false} />

	return (
		<div className={styles.list}>
			{!products.length && <div><p>There aren't any products to show.</p></div>}

			{products.map((product) => {
				return <Item {...product} />
			})}
		</div>
	)
}

export default List;
import { Link } from 'react-router-dom';

import styles from '../../../../styles/Admin.module.scss';

function Item({ _id, enabled, credits, price }) {
	return (
		<Link to={_id} className={`${styles.product} ${enabled ? "" : styles.disabled}`}>
			<p><strong>{credits}</strong> {`Token${credits === 1 ? '' : 's'}`} for <strong>£{(price / 100).toFixed(2)}</strong></p>
		</Link>
	)
}

export default Item;
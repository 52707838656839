import { useContext } from 'react';
import AppContext from '../../context';

import Meta from "./Meta";

import styles from '../../styles/Components.module.scss';

function Loading({ button }) {
	const context = useContext(AppContext);

	return (
		<>
			<Meta title={`Loading`} />

			<div className={`${styles.loading} ${button ? styles.button : null}`}>
				<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "none", display: "block", shapeRendering: "auto"}} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
					<circle cx="50" cy="50" fill="none" stroke={button ? "#ffffff" : context.domain.primaryColor} stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
						<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
					</circle>
				</svg>
			</div>
		</>
	)
}

export default Loading;
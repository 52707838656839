import { useState, useEffect } from "react";

import CreateProductAction from "../actions/CreateProduct.action";

import styles from '../../../../styles/Admin.module.scss';

function CreateProductForm(){
	const [product, setProduct] = useState({
		credits: 0,
		price: 0,
		enabled: true
	});

	const updateField = (e) => {
		setProduct({
			...product,
			[e.target.name]: e.target.value
		});
	}

	return (
		<div className={styles.form}>
			<div>
				<p>Tokens:</p>
				<input type="number" name="credits" value={product.credits} onChange={updateField} />
			</div>

			<div>
				<p>Price:</p>
				<input type="number" name="price" value={product.price} onChange={updateField} />
			</div>

			<select name="enabled" value={product.enabled} onChange={updateField}>
				<option value="true">Enabled</option>
				<option value="false">Disabled</option>
			</select>

			<CreateProductAction {...product} />
		</div>
	)
}

export default CreateProductForm;
import {Link, Outlet} from 'react-router-dom';

import List from './List';
import CreateMembershipPlanForm from './forms/CreateMembershipPlan.form';
import UpdateMembershipPlanForm from './forms/UpdateMembershipPlan.form';

import Meta from "../../components/Meta";

import styles from '../../../styles/Admin.module.scss';
import Header from "../../components/Header";

function Template() {
	return (
		<>
			<Meta title="Membership Plans" />

			<Header heading={"Membership Plans"} button={<Link to="create" className="btn btnPrimary">+ Create Membership Plan</Link>} />

			<main>
				<Outlet />
			</main>
		</>
	)
}

export default {
	Template,
	List,
	Create: CreateMembershipPlanForm,
	Update: UpdateMembershipPlanForm
};
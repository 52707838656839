import {useContext, useEffect, useState} from 'react';
import AppContext from "../../../context";

import Meta from "../../components/Meta";
import Header from "../../components/Header";
import Data from "../../../repository";
import Loading from "../../components/Loading";
import ChoosePlan from "./ChoosePlan";
import CurrentMembership from "./CurrentMembership";

function Membership() {
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(true);
    const [memberships, setMemberships] = useState([]);

    useEffect(async () => {
        setMemberships(await fetchMemberships());
        setLoading(false);
    }, [])

    const fetchMemberships = async () => {
        const result = await Data.fetchMemberships();
        return result.data;
    }

    if(loading) return <Loading button={false} />

    return (
        <>
            <Meta title="Membership" />

            <Header heading={"Membership"} />
            <main>
                {memberships.length === 0 || (memberships[memberships.length - 1].status !== 'active' && memberships[memberships.length - 1].status !== 'past_due') ? <ChoosePlan /> : <CurrentMembership />}
            </main>
        </>
    );
}

export default Membership;

import { useState, useEffect } from "react";
import { useParams, Link } from 'react-router-dom';

import Loading from "../../../components/Loading";
import UpdateStudioAction from "../actions/UpdateStudio.action";

import Data from '../../../../repository';

import styles from '../../../../styles/Admin.module.scss';

function UpdateStudioForm(){
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [studio, setStudio] = useState(null);

	useEffect(async () => {
		await fetchStudio();
	}, [])

	const fetchStudio = async () => {
		setLoading(true);
		const products = await Data.fetchStudio(id);
		setStudio(products.data);
		setLoading(false);
	}

	const updateField = (e) => {
		setStudio({
			...studio,
			[e.target.name]: e.target.value
		});
	}

	if(loading) return <Loading button={false} />

	return (
		<>
			<div className={styles.form}>
				<div>
					<p>Name:</p>
					<input type="text" name="name" value={studio.name} onChange={updateField} />
				</div>

				<div>
					<p>Lock:</p>
					<input type="text" name="lock" value={studio.lock} onChange={updateField} />
				</div>

				<select name="enabled" value={studio.enabled} onChange={updateField}>
					<option value="true">Enabled</option>
					<option value="false">Disabled</option>
				</select>

				<UpdateStudioAction {...studio} />
			</div>

			<div className={`${styles.form} ${styles.withheld}`}>
				<h2>Withheld Periods</h2>
				{studio.withheld.map((group) => {
					return (
						<Link to={`withheld/${group._id}`} className={styles.group}>
							<p>{group.name}</p>
							<p>{group.sessions.length} Periods</p>
						</Link>
					)
				})}
				<Link to={`/admin/studios/${studio._id}/withheld/create`} className="btn btnPrimary">Create Withheld Periods</Link>
			</div>
		</>
	)
}

export default UpdateStudioForm;
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

import moment from 'moment';

import Loading from "../../../components/Loading";
import UpdateDiscountCodeAction from "../actions/UpdateDiscountCode.action";

import Data from '../../../../repository';

import styles from '../../../../styles/Admin.module.scss';

function UpdateDiscountCodeForm(){
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [discountCode, setDiscountCode] = useState(null);

	useEffect(async () => {
		await fetchDiscountCode();
	}, [])

	const fetchDiscountCode = async () => {
		setLoading(true);
		const discountCode = await Data.fetchDiscountCode(id);
		setDiscountCode(discountCode.data);
		setLoading(false);
	}

	const updateField = (e) => {
		setDiscountCode({
			...discountCode,
			[e.target.name]: e.target.value
		});
	}

	if(loading) return <Loading button={false} />

	return (
		<div className={styles.form}>
			<div>
				<p>Name:</p>
				<input type="string" name="name" value={discountCode.name} onChange={updateField} />
			</div>

			<div>
				<p>Code:</p>
				<input type="string" name="code" value={discountCode.code} onChange={updateField} disabled={true} />
			</div>

			<div>
				<p>Percentage Off:</p>
				<input type="number" name="percentageOff" min={0} max={99} value={discountCode.percentageOff} disabled={true} onChange={updateField} />
			</div>

			<div>
				<p>Valid From:</p>
				<input type="date" name="start" value={moment(discountCode.start).format("YYYY-MM-DD")} onChange={updateField} />
			</div>

			<div>
				<p>Valid Until:</p>
				<input type="date" name="end" value={moment(discountCode.end).format("YYYY-MM-DD")} onChange={updateField} />
			</div>

			<select name="enabled" value={discountCode.enabled} onChange={updateField}>
				<option value="true">Enabled</option>
				<option value="false">Disabled</option>
			</select>

			<UpdateDiscountCodeAction {...discountCode} />
		</div>
	)
}

export default UpdateDiscountCodeForm;
import { useState } from "react";
import { Link } from "react-router-dom";

import Loading from "../../components/Loading";
import Meta from "../../components/Meta";

import Data from '../../../repository';

import styles from "../../../styles/Entry.module.scss";

function ForgottenPassword() {
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({
		email: "",
		success: false,
		error: null
	});

	const onChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.value
		})
	}

	const onSubmit = async (event) => {
		event.preventDefault();
		setLoading(true);

		try {
			await Data.requestPasswordReset(state.email);
			setState({
				email: "",
				success: true
			})
		} catch(error) {
			setState({
				email: "",
				error: error.response.data
			})
		} finally {
			setLoading(false);
		}
	}

	if(!state.success){
		return (
			<>
				<Meta title="Forgotten Password?" />

				<div>
					{state.error &&
					<div className={styles.error}>
						<p>{state.error.message}</p>
					</div>
					}

					<h1>Forgotten Password</h1>

					<form onSubmit={onSubmit}>
						<input type="email" name="email" placeholder="Email Address" value={state.email} onChange={onChange} maxLength={255} required={true} />
						<button className="btn btnPrimary" disabled={loading}>
							{loading ?
								<Loading button={true} />
								:
								`Request Password Reset`
							}
						</button>
					</form>

					<div className={styles.alternatives}>
						<Link to="/entry/login">Remember your password?</Link>
					</div>
				</div>
			</>
		);
	} else {
		return (
			<div>
				<h1>Forgotten Password</h1>
				<p>We've sent a password reset link to your email address. If you don't receive the email, check your spam folder.</p>

				<div className={styles.alternatives}>
					<Link to="/entry/login">Back to Login</Link>
				</div>
			</div>
		);
	}
}

export default ForgottenPassword;

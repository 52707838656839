import { Link } from 'react-router-dom';

import styles from '../../../../styles/Admin.module.scss';

function Item({ _id, active, name, amount, interval_count, interval }) {
	return (
		<Link to={_id} className={`${styles.product} ${active ? "" : styles.disabled}`}>
			<p>{name} <strong>£{(amount / 100).toFixed(2)}</strong> every {interval_count} {interval}{interval_count > 1 ? 's' : ''}</p>
		</Link>
	)
}

export default Item;
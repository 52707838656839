import { Capacitor } from "@capacitor/core";

import styles from '../../styles/SidebarMembershipStatus.module.scss';
import {useEffect, useState} from "react";
import Data from "../../repository";

function SidebarMembershipStatus() {

    const [loading, setLoading] = useState(true);
    const [memberships, setMemberships] = useState([]);

    useEffect(async () => {
        setMemberships(await fetchMemberships());
        setLoading(false);
    }, [])

    const fetchMemberships = async () => {
        const result = await Data.fetchMemberships();
        return result.data;
    }

    if(loading) return null

    return (
        <div className={styles.sidebarMembershipStatus}>
            <p>Membership <strong>{memberships.length === 0 ? 'Inactive' : memberships[memberships.length - 1].status === 'active' ? 'Active' : memberships[memberships.length - 1].status === 'past_due' ? 'Past Due' : memberships[memberships.length - 1].status === 'canceled' ? 'Cancelled' : 'Inactive'}</strong></p>
        </div>
    )
}

export default SidebarMembershipStatus;
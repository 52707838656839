import Data from "../Data";
import {useContext, useEffect, useState} from "react";
import Error from "../admin/components/Error";
import SplitColumns from "../account/components/SplitColumns";
import moment from "moment/moment";
import AppContext from "../../../context";
import {useParams} from "react-router-dom";

const Confirm = () => {
    const { id } = useParams()
    const context = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    const getQueryParam = (param) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }

    const eventId = getQueryParam('event');
    const tickets = getQueryParam('tickets');

    const [experience, setExperience] = useState([]);
    const [event, setEvent] = useState(null);

    useEffect(async () => {
        try {
            const experience = await Data.fetchExperienceById( { id})
            setExperience(experience.data)
            const event = await Data.fetchEventById( { id})
            setEvent(event.data)
        } catch (error) {
            window.location.href = "/"
        }
    }, []);

    const book = async () => {
        setLoading(true)

        try {
            if(window.confirm('Stripe payment window will open here.')){
                const booking = await Data.createExperienceBooking({ eventId, tickets });
                window.location.href = `/account/bookings/${booking.data._id}?success=true`;

                setError(false)
            }
        } catch(error) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={"p-8"}>
            <div className={"container mx-auto"}>
                <div className={"grid grid-cols-1 gap-4 "}>
                    <h1>Booking Summary</h1>

                    <div className={"border rounded-lg p-6"}>
                        <SplitColumns>
                            <p>Your Name</p>
                            <p>{context.user.firstName} {context.user.lastName}</p>
                            <p>Your Email Address</p>
                            <p>{context.user.email}</p>
                            <p>Experience</p>
                            <p>{experience.name}</p>
                            <p>Date</p>
                            <p>{moment(event?.date).format("MMM Do, YYYY")}</p>
                            <p>Time</p>
                            <p>{moment(event?.date).format("HH:mm A")}</p>
                            <p>Tickets</p>
                            <p>{tickets} ticket{tickets > 1 ? 's' : ''}</p>
                            <p>Total Amount</p>
                            <p>£{tickets * experience.price}</p>
                        </SplitColumns>
                    </div>

                    <div>
                    <button disabled={loading}
                                className={"inline-block bg-primary py-2 px-4 text-white text-center rounded-md mt-6 border-0"}
                                onClick={book}>{loading ? 'Redirecting...' : 'Continue to Payment'}</button>
                    </div>

                    {error ? <Error message={"There was an error creating your booking, please try again."}/> : null}
                </div>
            </div>
        </div>
    )
}

export default Confirm
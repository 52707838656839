import Header from "./components/Header";
import AccountSection from "./components/AccountSection";
import GreyLink from "./components/GreyLink";
import BookingPreview from "./components/BookingPreview";
import SplitColumns from "./components/SplitColumns";
import Empty from "../components/Empty";
import AppContext from "../../../context";
import {useContext, useEffect, useState} from "react";
import Data from "../Data";
import Meta from "../../components/Meta";

const Account = () => {
    const context = useContext(AppContext);

    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(async () => {
        try {
            const bookings = await Data.fetchAllExperienceBookings()
            setBookings(bookings.data)
        } catch(error){
            setError(true)
        } finally {
            setLoading(false)
        }
    }, []);

    return (
        <>
            <Meta title={`My Account`} />
            <Header heading={`Hi, ${context.user.firstName}!`} subHeading={context.user.email} button={<GreyLink to={"/log-out"} label={"Log Out"} />} />

            <div className={"container mx-auto p-6 pt-0 grid grid-cols-1 gap-4"}>
                <AccountSection>
                    <h3>Bookings</h3>
                    {bookings.length === 0 ?
                        <Empty>You haven't made any bookings yet.</Empty>
                    :
                        <div className={"grid grid-cols-1 gap-4 lg:grid-cols-2"}>
                            {bookings.map(booking => <BookingPreview key={booking.id} booking={booking} />)}
                        </div>
                    }

                </AccountSection>
                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-4"}>
                    <AccountSection>
                        <h3>My Details</h3>
                        <SplitColumns>
                            <p>First Name</p>
                            <p>{context.user.firstName}</p>
                            <p>Last Name</p>
                            <p>{context.user.lastName}</p>
                            <p>Email Address</p>
                            <p>{context.user.email}</p>
                            <p>Phone Number</p>
                            <p>{context.user.phoneNumber}</p>
                        </SplitColumns>
                        <GreyLink to={"/account/update-details"} label={"Update Details"}/>
                    </AccountSection>
                    <AccountSection>
                        <h3>Marketing Preferences</h3>
                        <p className={"text-textGrey"}>You are currently subscribed to receive news and updates about {context.domain.name}.</p>
                        <GreyLink to={""} label={"Unsubscribe"} />
                    </AccountSection>
                    <AccountSection>
                        <h3>Account Security</h3>
                        <p className={"text-textGrey"}>Use the button below to request a password reset link sent to you via
                            email.</p>
                        <GreyLink to={""} label={"Reset Password"} />
                    </AccountSection>
                </div>
            </div>
        </>
    )
}

export default Account
import { Link } from 'react-router-dom';

import styles from '../../../../styles/Admin.module.scss';

function Item(user) {
    return (
        <Link to={user._id} className={`${styles.membership} ${user.membership ? "" : styles.disabled}`}>
            <p>{user.firstName} {user.lastName} ({user.email})</p>
            {user.membership ?
                <p className={`${styles.membershipInfo} ${user.membership.status === 'past_due' ? styles.pastDue : null} ${user.membership.status === "active" ? styles.active : null}`}>{user.membership.status}: {user.membership.plan}</p> : null}
        </Link>
    )
}

export default Item;
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "../../../components/Loading";

import Data from "../../../../repository";

function UpdateProductAction(product) {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	const execute = async () => {
		setLoading(true);
		await Data.updateProduct(product);
		setLoading(false);
		navigate("/admin/products")
	}

	return (
		<button onClick={execute} className="btn btnPrimary" disabled={loading}>
			{loading ?
				<Loading button={true} />
			:
				`Save Changes`
			}
		</button>
	)
}

export default UpdateProductAction;
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import GoBack from "../components/GoBack";
import InputWrapper from "../components/InputWrapper";
import CheckoutHeader from "../components/CheckoutHeader";
import {Link, useLocation, useParams} from "react-router-dom";
import {useContext, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import Repository from "../../../repository";
import AppContext from "../../../context";
import {Check} from "react-feather";
import Error from "../admin/components/Error";
import Data from "../../../repository";

const CreateAccount = () => {
    const context = useContext(AppContext);
    const { id } = useParams()
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    const { register, handleSubmit, formState: { errors }, control } = useForm()

    const email = useWatch({
        name: "email",
        control
    })

    const password = useWatch({
        name: "password",
        control
    })

    const subscribe = useWatch({
        name: "subscribe",
        control
    })

    const submit = async (data) => {
        setLoading(true)

        try {
            await Repository.createAccount(data)
            const result = await Data.login(email, password);
            localStorage.setItem("token", result.data.token);
            window.location.href = `/${id}/checkout/confirm${location.search}`;

            setLoading(false)
            setError(false)
        } catch(error) {
            setLoading(false)
            setError(true)
        }
    }

    return (
        <div className={"p-8"}>
            <form className={"container mx-auto grid grid-cols-1 gap-4"} onSubmit={handleSubmit(submit)}>
                <h1>Your Details</h1>

                <div className={"grid grid-cols-1 gap-4 lg:grid-cols-2"}>
                    <InputWrapper label={"Email Address"} input={
                        <input type={"email"} {...register('email', { required: true })} />
                    } error={errors.email ? 'This is a required field' : null}/>

                    <InputWrapper label={"Password"} input={
                        <input type={"password"} {...register('password', { required: true })} />
                    } error={errors.password ? 'This is a required field' : null}/>

                    <InputWrapper label={"First Name"} input={
                        <input type={"text"} {...register('firstName', { required: true })} />
                    } error={errors.firstName ? 'This is a required field' : null}/>

                    <InputWrapper label={"Last Name"} input={
                        <input type={"text"} {...register('lastName', { required: true })} />
                    } error={errors.lastName ? 'This is a required field' : null}/>

                    <InputWrapper label={"Phone Number"} input={
                        <input type={"text"} {...register('phoneNumber', { required: true })} />
                    } error={errors.phoneNumber ? 'This is a required field' : null}/>
                </div>

                <label className={"flex items-center gap-2 cursor-pointer"}>
                    <div className={"relative w-9 h-9"}>
                        <input type={"checkbox"} {...register('subscribe')}
                               className={"w-full h-full checked:bg-primary"}/>
                        {subscribe ? <Check color={'#ffffff'} className={"absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"} /> : null}
                    </div>
                    Receive news and updates about {context.domain.name}.
                </label>

                <div>
                <button type={'submit'} disabled={loading}
                          className={"inline-block bg-primary py-2 px-4 w-full lg:w-auto text-white text-center rounded-md border-0"}>{loading ? 'Creating Account...' : 'Continue'}</button>
                </div>

                {error ? <Error message={"There was an error creating your account, please try again."} /> : null }
            </form>
        </div>
    )
}

export default CreateAccount
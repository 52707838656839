import Select from 'react-select';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { addDays } from 'date-fns';

import styles from '../../../../styles/Admin.module.scss';

const _removeDuplicates = (arr) => {
	return arr.filter((obj, idx, self) => idx === self.findIndex(t => t.value === obj.value))
}

function Filters({bookings, filters, setFilters}) {
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		setFilters({
			user: searchParams.get('user'),
			start: searchParams.get('start'),
			end: searchParams.get('end'),
			studio: searchParams.get('studio')
		})
	}, []);

	const updateFilters = (key, value) => {
		setFilters({
			...filters,
			[key]: value
		})

		setSearchParams({
			...filters,
			[key]: value
		})
	}

	const studios = _removeDuplicates(bookings.map((booking) => {
		return {
			value: booking.session.Studio._id,
			label: booking.session.Studio.name
		};
	}));

	const users = _removeDuplicates(bookings.map((booking) => {
		return {
			value: booking.User._id,
			label: `${booking.User.firstName} ${booking.User.lastName} (${booking.User.email})`
		};
	}));

	return <div className={styles.filters}>
		<div>
			<p>User</p>

			<Select
				className={styles.select}
				value={users.find(user => user.value === filters.user)}
				options={users}
				onChange={(e) => updateFilters('user', e.value)}
				isMulti={false}
			/>
		</div>

		<div>
			<p>Start Date</p>
			<input type="date" value={searchParams.get('start')} onChange={(e) => { updateFilters('start', e.target.value) }} />
		</div>

		<div>
			<p>End Date</p>
			<input type="date" value={searchParams.get('end')} onChange={(e) => { updateFilters('end', e.target.value) }} />
		</div>

		<div>
			<p>Studio</p>
			<select onChange={(e) => {
				updateFilters('studio', e.target.value)
			}} value={filters.studio}>
				<option value="">All Studios</option>
				{studios.map((studio) => {
					return <option value={studio.value}>{studio.label}</option>
				})}
			</select>
		</div>

		{/*<button className="btn btnEmpty" onClick={() => {*/}
		{/*	setFilters({*/}
		{/*		user: '',*/}
		{/*		start: '',*/}
		{/*		end: '',*/}
		{/*		studio: ''*/}
		{/*	})*/}

		{/*	setSearchParams({*/}
		{/*		user: '',*/}
		{/*		start: '',*/}
		{/*		end: '',*/}
		{/*		studio: ''*/}
		{/*	})*/}
		{/*}}>Reset</button>*/}
	</div>
}

export default Filters;
import { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';

import moment from 'moment';

import Loading from "../../components/Loading";

import Data from '../../../repository';

import styles from '../../../styles/Admin.module.scss';

function Existing(){
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	const [transaction, setTransaction] = useState(null);

	useEffect(async () => {
		await fetchTransaction();
	}, [])

	const fetchTransaction = async () => {
		setLoading(true);
		const transaction = await Data.fetchTransaction(id);
		setTransaction(transaction.data);
		setLoading(false);
	}

	if(loading) return <Loading button={false} />

	const manual = !transaction.Product;

	const giftVoucher = transaction.giftVoucher?.code;

	return (
		<>
			<div className={styles.form}>
				{giftVoucher ?
					<>
						<div>
							<p>Gift Voucher:</p>
							<input type="text" value={`Yes`} disabled={true} />
						</div>

						<div>
							<p>Purchased By:</p>
							<input type="text" value={transaction.giftVoucher.purchaser} disabled={true} />
						</div>

						<div>
							<p>Redeemed By:</p>
							<input type="text" value={!transaction.User ? 'Unredeemed' : `${transaction.User.firstName} ${transaction.User.lastName} (${transaction.User.email})`} disabled={true} />
						</div>

						<div>
							<p>Voucher Code:</p>
							<input type="text" value={transaction.giftVoucher.code} disabled={true} />
						</div>
					</>
				:
					<div>
						<p>User:</p>
						<input type="text" value={`${transaction.User.firstName} ${transaction.User.lastName} (${transaction.User.email})`} disabled={true} />
					</div>
				}


				{manual ?
					<>
						<div>
							<p>Tokens:</p>
							<input type="text" value={transaction.adjustment} disabled={true} />
						</div>
						<div>
							<p>Note:</p>
							<input type="text" value={transaction.note} disabled={true} />
						</div>
					</>
				:
					<>
						<div>
							<p>Tokens:</p>
							<input type="text" value={transaction.Product.credits} disabled={true} />
						</div>
						<div>
							<p>Amount:</p>
							<input type="text" value={`£${(transaction.Product.price / 100).toFixed(2)}`} disabled={true} />
						</div>
						<div>
							<p>PayPal ID:</p>
							<input type="text" value={transaction.orderId} disabled={true} />
						</div>
					</>
				}
				<div>
					<p>Timestamp:</p>
					<input type="text" value={moment(transaction.createdAt).format("YYYY-MM-DD HH:mm:ss")} disabled={true} />
				</div>
				{transaction.DiscountCode &&
					<div>
						<p>Discount Code:</p>
						<input type="text" value={`${transaction.DiscountCode.name} ${transaction.DiscountCode.percentageOff}% Off (${transaction.DiscountCode.code})`} disabled={true} />
					</div>
				}
			</div>
		</>
	)
}

export default Existing;
import { useState } from "react";

import CreateDiscountCodeAction from "../actions/CreateDiscountCode.action";

import styles from '../../../../styles/Admin.module.scss';

function CreateDiscountCodeForm(){
	const [discountCode, setDiscountCode] = useState({
		name: "",
		code: "",
		percentageOff: 0,
		start: null,
		end: null,
		enabled: true
	});

	const updateField = (e) => {
		setDiscountCode({
			...discountCode,
			[e.target.name]: e.target.value
		});
	}

	return (
		<div className={styles.form}>
			<div>
				<p>Name:</p>
				<input type="string" name="name" value={discountCode.name} onChange={updateField} />
			</div>

			<div>
				<p>Code:</p>
				<input type="string" name="code" value={discountCode.code} onChange={updateField} />
			</div>
			
			<div>
				<p>Percentage Off:</p>
				<input type="number" name="percentageOff" min={0} max={99} value={discountCode.percentageOff} onChange={updateField} />
			</div>

			<div>
				<p>Valid From:</p>
				<input type="date" name="start" value={discountCode.start} onChange={updateField} />
			</div>

			<div>
				<p>Valid Until:</p>
				<input type="date" name="end" value={discountCode.end} onChange={updateField} />
			</div>
			
			<select name="enabled" value={discountCode.enabled} onChange={updateField}>
				<option value="true">Enabled</option>
				<option value="false">Disabled</option>
			</select>
			
			<CreateDiscountCodeAction {...discountCode} />
		</div>
	)
}

export default CreateDiscountCodeForm;
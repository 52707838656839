import React, {useContext, useEffect, useState} from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import AppContext from "../../context";

import Data from "../../repository";

import styles from "../../styles/Paypal.module.scss";

export default function PayPal({product, setSuccess, discountCode}) {
    const context = useContext(AppContext);
    const [ErrorMessage, setErrorMessage] = useState("");
    const [orderID, setOrderID] = useState(false);

    // creates a paypal order
    const createOrder = async (data, actions) => {
        const order = await Data.createPaypalGiftVoucherOrder(product._id, discountCode);
        setOrderID(order.data.id);
        return order.data.id;
    };

    // check Approval
    const onApprove = async  (data, actions) => {
        setErrorMessage("");
        await Data.approvePaypalGiftVoucherOrder(data.orderID);
        setSuccess(true);
        await context.fetchUser();
    };

    //capture likely error
    const onError = (data, actions) => {
        console.log('Error', data);
        setErrorMessage("An error occurred with your payment, please try again.");
    };

    return (
        <>
            {ErrorMessage && <p className={styles.error}>{ErrorMessage}</p>}
            <PayPalScriptProvider
                options={{
                    "client-id": "AQSD0hkAoVGUJfrxQyWY-VTsonfxY1QmHxLuVixIE55mWYi0UOt6E0V1ez_7LtUVXPKMSPq3uGvQcbHY",
                    "currency": "GBP"
                }}
            >
                <PayPalButtons
                    style={{ layout: "vertical", height: 50 }}
                    createOrder={createOrder}
                    onApprove={onApprove}
                    onError={onError}
                    currency="GBP"
                />
            </PayPalScriptProvider>
        </>
    );
}
import { useState } from 'react';

import Session from "./Session";

import moment from "moment";

import styles from "../../../../styles/Calendar.module.scss";

function Day({day, sessions, studio}) {
	const [open, setOpen] = useState(false);

	sessions = sessions.filter((session) => {
		return session.start.split("T")[0] === day;
	})

	return (
		<div className={`${styles.day} ${open ? styles.open : null}`}>
			<div className={styles.heading} onClick={() => { setOpen(!open) }}>
				<img src={require('../../../../images/drop-down.svg').default} alt="" />
				<p><strong>{moment(day).format('ddd')}</strong> {moment(day).format('Do')} {moment(day).format('MMM')}</p>
			</div>

			<div className={styles.times}>
				{sessions.map(session => <Session session={session} studio={studio} /> )}
			</div>
		</div>
	)
}

export default Day;
import { Link, Outlet } from 'react-router-dom';

import List from './List';
import Existing from './Existing';
import CreateTransactionForm from './forms/CreateTransaction.form';

import Meta from "../../components/Meta";

import styles from '../../../styles/Admin.module.scss';
import Header from "../../components/Header";

function Template() {
	return (
		<>
			<Meta title="Transactions" />

			<Header heading={"Transactions"} button={<Link to="create" className="btn btnPrimary">+ Create Transaction</Link>} />

			<main>
				<Outlet />
			</main>
		</>
	)
}

export default {
	Template,
	List,
	Existing,
	Create: CreateTransactionForm
};
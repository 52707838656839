import { Link } from 'react-router-dom';

import styles from '../../../../styles/Admin.module.scss';

function Item({ _id, name, percent_off, code}) {
	return (
		<Link to={_id} className={`${styles.discountCode}`}>
			<p>{name} ({code}): {percent_off}% Off</p>
		</Link>
	)
}

export default Item;
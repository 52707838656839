import { Outlet } from 'react-router-dom';
import Meta from "../components/Meta";

import styles from '../../styles/GiftVouchers.module.scss'

function GiftVouchersTemplate() {
    return (
        <>
            <Meta title="Gift Vouchers" />
            <main className={styles.giftVouchers}>
                <div>
                    <div className={styles.header}>
                        <img src={require('../../images/logo-blue.svg').default} alt={'Fit Hub Logo'} />
                        <h1>Gift Vouchers</h1>
                    </div>
                    <Outlet />
                    <div className={styles.footer}>
                        <a href={"/"}>Exit to Main Website</a>
                    </div>
                </div>
                <span />
            </main>
        </>
    );
}

export default GiftVouchersTemplate

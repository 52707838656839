import { useState, useEffect } from 'react';

import Loading from "../../components/Loading";

import Item from './components/Item';

import Data from "../../../repository/index";

import styles from "../../../styles/Admin.module.scss";

function Transactions() {
	const [loading, setLoading] = useState(true);
	const [transactions, setTransactions] = useState([]);

	useEffect(async () => {
		let transactions = await Data.fetchTransactions();
		transactions = transactions.data.sort((a, b) => {
			return new Date(b.createdAt) - new Date(a.createdAt);
		})
		setTransactions(transactions);
		setLoading(false);
	}, [])

	if(loading) return <Loading button={false} />

	return (
		<div className={styles.list}>
			{!transactions.length && <div><p>There aren't any transactions to show.</p></div>}

			{transactions.map((transaction) => {
				return <Item {...transaction} />
			})}
		</div>
	)
}

export default Transactions;
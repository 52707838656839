import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Booking from './Booking';
import Loading from '../../components/Loading';
import Meta from "../../components/Meta";
import Header from "../../components/Header";

import Data from "../../../repository";

import styles from '../../../styles/Bookings.module.scss';

function Bookings() {
	const [loading, setLoading] = useState(true);
	const [bookings, setBookings] = useState([]);

	useEffect(async () => {
		await refreshBookings();
	}, [])

	const fetchBookings = async () => {
		const result = await Data.fetchBookings();
		return result.data;
	}

	const refreshBookings = async () => {
		setBookings(await fetchBookings());
		setLoading(false);
	}

	if(loading) return <Loading button={false} />

	return (
		<>
			<Meta title="Manage Bookings" />

			<Header heading={"Manage Bookings"} />
			<main>
				<div className={styles.bookings}>
					{!bookings.length && <div className={styles.none}><p>You haven't made any bookings yet. Create your first booking <Link to="/book">here</Link>.</p></div>}

					{bookings.map((booking) => {
						return <Booking {...booking} refreshBookings={refreshBookings} />
					})}
				</div>
			</main>
		</>
	);
}

export default Bookings;

import Meta from "../components/Meta";
import {useContext} from "react";
import AppContext from "../../context";
import {Link} from "react-router-dom";
import {X} from "react-feather";

const Contact = () => {
    const context = useContext(AppContext);

    return (
        <>
            <Meta title={'Contact'} />
            <div className={"fixed w-full h-full bg-black bg-opacity-50 z-[100] top-0 left-0"}>
                <div className={"absolute top-6 sm:top-1/2 left-1/2 transform -translate-x-1/2 sm:-translate-y-1/2 p-4 bg-white rounded-lg w-[90%] sm:w-96"}>
                    <div className={'flex justify-between items-center'}>
                        <p>Contact {context.domain.name}</p>
                        <Link to={"/"}><X size={20} /></Link>
                    </div>
                    <div className={"mt-4"}>
                        <p>Tel: +44 7624 308679</p>
                        <p>Email: imtomgough@gmail.com</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
import { Capacitor } from "@capacitor/core";

import styles from '../../styles/Header.module.scss';

function Header({ heading, button }) {

	const isNative = Capacitor.isNativePlatform();

	return (
		<div className={`${styles.header} ${isNative ? styles.native : null}`}>
			<h1>{heading}</h1>
			{button ? button : null}
		</div>
	)
}

export default Header;